.title {
  text-align: center;
  font-size: 20px;
  color: rgb(46, 199, 201);
  font-weight: bold;
}

.layout {
  display: flex;
}

.cities {
  flex-shrink: 0;
  flex-grow: 0;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.city {
  position: relative;
  margin-bottom: 12px;
  line-height: 26px;
  color: #347afc;
  border: 1px #347afc solid;
  text-align: center;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 6px;
    height: 6px;
    border-left: 3px #347afc solid;
    border-top: 3px #347afc solid;
    border-right: 3px transparent solid;
    border-bottom: 3px transparent solid;
    transform: rotate(180deg);
  }

  &.current {
    color: #ea5514;
    border-color: #ea5514;

    &:after {
      border-left-color: #ea5514;
      border-top-color: #ea5514;
    }
  }
}

.chart {
  flex: 1;
}
