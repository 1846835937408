.root{
  display: flex;
}

.left{
  flex-basis: 277px;
  //background: #cbe4fd;
  border-radius: 0 0 0 24px;
  //border-top: 1px #91a3cd solid;
  //border-right: 1px #91a3cd solid;
  overflow: hidden;
  border-right: 1px solid #ddd;
}

.middle{
  display: none;
  flex-basis: 260px;
  color: #333;
  font-size: 16px;
}

.right{
  flex: 1;
  margin-left: 20px;
  padding-right: 20px;
  overflow: auto;
  min-height: 400px;
  max-height: 500px;
  padding-bottom: 30px;
}

.right > div{

}

.title{
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  padding: 0 26px 0 80px;
  min-height: 72px;
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 570;
  color: #333333;
  line-height: 30px;
  vertical-align: center;
  &:first-child{
    border-top:  1px solid #ddd;
  }

  &:before{
    position: absolute;
    content: "";
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    background-position-x: 0;
    background-size: 100% 100% !important;
  }

  &.active{
    color: #FFFFFF;
    background-color: #4393f4;
    //font-weight: bold;
    position: relative;
    &::after{
      content: '';
      width: 6px;
      height: calc(100% + 2px);
      background: #4393f4;
      right: 0;
      top: -1px;
      position: absolute;
    }
    &:before{
      background-position-x: -43px;
    }
  }
}

.block1{
  .title:nth-child(1):before{
    background: url("./img/tag2/icon1_cur.png") left center no-repeat ;
  }
  .title:nth-child(1).active:before{
    background-image: url("./img/tag2/icon1.png");
  }
  .title:nth-child(2):before{
    background: url("./img/tag2/icon2_cur.png") left center no-repeat ;
  }
  .title:nth-child(2).active:before{
    background-image: url("./img/tag2/icon2.png");
  }
  .title:nth-child(3):before{
    background: url("./img/tag2/icon3_cur.png") left center no-repeat ;
  }
  .title:nth-child(3).active:before{
    background-image: url("./img/tag2/icon3.png");
  }
}
