@import "src/components/Style/mixin";

.root{
  height: 100vh;
  background: url("./img/bg.png") center top / cover no-repeat;
  :global{
    .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
      width: 100%;
    }
    .ant-layout{
      background: transparent;
    }
  }
}

.header{
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  background-color: #fff;
  user-select: none;
}

.logo{
  flex: 0;
  flex-basis: 350px;
  color: #333333;
  font-size: 24px;
  font-weight: bold;
  padding-left: 50px;
  background: url(./img/logo.png) no-repeat center left;
  margin-right: 15px;
}

.Sider{
  border-right: 1px #eee solid;
  user-select: none;
  color: #fff;
  overflow: auto;
  background-image: url(./img/sidebar.png);
  background-repeat: no-repeat;
  :global{
    .ant-menu-sub.ant-menu-inline{
      background-color: transparent;
    }
    .ant-menu-submenu-selected{
      color: #fff;
    }
    .ant-menu-item-selected{
      color: #fff;
      background: linear-gradient(to right, #0255FD, #367BFC);
      // background-color: transparent;
    }
    .ant-menu-item, .ant-menu-submenu-title, .ant-menu-item-only-child{
      margin: 0 !important;
    }
    .ant-menu-item, .ant-menu-submenu-title{
      height: 60px !important;
      line-height: 60px !important;
      color: #fff;
      transition: none;
      &:active, &:focus, &:hover{
        background: linear-gradient(to right, #0255FD, #367BFC);
        // background-color: transparent;
        .ant-menu-submenu-arrow{
          color: #fff;
        }
      }
    }
    .ant-menu-submenu-arrow{
      color: #fff;
    }
    .ant-menu{
      background-color: transparent;
    }
  }
}

.Breadcrumb{
  padding-bottom: 20px;
  background: transparent;
  user-select: none;
}

.content{
  overflow: auto;
  padding: 24px;
  padding-top: 20px;
  margin: 0;
  min-height: 280px;

  @include mobile{
    padding: 15px;
  }
}

.drawer{

  :global{
    .ant-drawer-body{
      padding: 0;
      overflow: auto;
    }
  }
}
