@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;

  @include mobile {
    width: auto;
  }
}

.header {
  padding-left: 10px;
  height: 80px;
  background-color: #fff;

  @include mobile {
    height: auto;
    padding-right: 10px;
  }
}

.schoolName {
  height: 35px;
  padding-top: 22.5px;
  display: inline-block;
  margin: 0 20px;

  @include mobile {
    display: block;
    height: auto;
    margin: 0;
    padding-top: 10px;

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-input,
      .ant-select {
        width: 100% !important;
      }
    }
  }
}

.searchContent {
  width: 180px !important;
  height: 35px;
}

.btns {
  display: inline-block;

  @include mobile {
    margin-top: 30px;
  }
}

.search {
  width: 80px;
  height: 35px;
  margin-right: 10px;
  border-radius: 3px;
}

.reset {
  width: 80px;
  height: 35px;
  border-radius: 3px;
  color: #999999;
}

.tableContent {
  width: 100%;
  background-color: #fff;
  padding-bottom: 80px;
}

.tableTitle {
  margin: 25px 0 0 0;
  padding: 0 20px;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  h1 {
    color: #666666;
  }
  @include mobile {
    font-size: 22px;
  }
}
.tableTitle1 {
  padding: 0 20px;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  h1 {
    color: #666666;
  }
  @include mobile {
    font-size: 22px;
  }
}
.table {
  padding: 0 20px;

  @include mobile {
    padding: 0;
  }

  :global {
    .ant-table-thead > tr > th {
      color: #333333;
      background: #f7f8fc !important;
    }
  }
}

.shallow_gray {
  color: #666666;
  background: #f7f8fc !important;
}

.deep_gray {
  color: #666666;
}
.showBox {
  .table {
    width: 100%;
    margin: 0 auto;
    color: #999;
    border-collapse: collapse;
    border-radius: 10px;
    @include mobile {
      white-space: nowrap;
    }
    tr,
    th,
    td {
      padding: 12px 20px;
      text-align: center;
      font-size: 14px;
    }

    tr:nth-child(2n + 1) {
      background: #f7f8fc;
    }

    &Wrapper {
      height: 600px;
      background: #fff;
      border-radius: 16px;
      overflow: hidden;

      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #535353;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: #ededed;
      }
    }

    &Title {
      // padding: 40px 50px;
      color: #ffffff;
      font-size: 24px;
      background: #367bfc;
    }
  }

  .input {
    color: #999;
    height: 25px;
    line-height: 25px;
    display: inline-block;
    margin-left: 20px;
    outline: none;
    border: none;
    padding-bottom: 5px;
    width: 280px;
    font-size: 16px;
  }
  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #999;
    font-size: 16px;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999;
    font-size: 20px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #999;
    font-size: 20px;
  }
  .tableService {
    tr,
    th,
    td {
      padding: 20px 5px;
      border: 1px solid #2ec7c9;
      text-align: center;
      font-size: 20px;
    }
  }

  .wrapper {
    height: calc(600px - 117px);
    overflow: auto;
  }

  .tableList {
    //position: relative;
    //width: 100%;
    //height: 480px;
    //display: block;
    //overflow-y: scroll;
  }

  .active {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
  }
  .btn {
    position: absolute;
    top: 50px;
    right: 30px;
    @include mobile {
      top: 74px;
    }
  }
  .tabScroll {
    width: 100%;
    height: 480px;
    overflow: scroll;
    @include mobile {
      height: 380px;
    }
  }
}
.mar0{
  margin: 0 !important;
}
