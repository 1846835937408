@import "src/components/Style/mixin";

.wrapper {
  padding: 20px 100px;
  @include mobile {
    padding: 0;
    padding-top: 20px;
  }
}

.title {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}

.searchResult {
  display: inline-block;
  width: 50%;
}
