
.root{
  margin-bottom: 10px;
  background: rgba(69, 122, 151, 1);
  color: #fff;
}

.logo{
  line-height: 64px;
  font-size: 24px;
  font-weight: bold;
}

.nav{
  border-top: 1px lighten(#457a97, 10%) solid;
}

.wrapper{
  display: flex;
}

.link{
  flex-basis: 0;
  flex-grow: 1;
  display: inline-block;
  line-height: 32px;
  color: #fff;
  vertical-align: middle;
  text-align: center;

  &:hover{
    color: #fff;
  }
}

.current{
  background: #52c2f5;
}
