@import "src/components/Style/mixin";

.root {
  background: url("../../../src/assets/image/chartBg.jpg") left center/ cover no-repeat;
  min-width: 1920px;
  height: 100vh;
  overflow: hidden;
  @include mobile {
    width: auto;
  }
}
.wrapper {
  padding-top: 115px;
  //background-color:rgba(5, 12, 41, 0.85);
}

.datavLayout {
  // position: absolute;
  left: 0;
  top: 0;
  width: 1920px;
  height: 1200px;
  transform-origin: left top 0px;
}

.barClass {
  width: 600px;
  position: relative;
  top: 500px;
  background-color: #fff;
}

// .map{
//   position: absolute;
//   left: 800px;
//   top: 335px;
//   width: 1200px;
//   height: 800px!important;
// }

.timeTableBtn {
  position: absolute;
  top: 462px;
  left: 740px;
  color: #dddddd;
  line-height: 40px;
  border: 1px solid #dddddd;
  border-radius: 20px;
  z-index: 1;
  padding: 0 30px;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 20px;
  z-index: 9;
}

.timeTableBtn:hover {
  border-color: #00baff;
  background: #00baff;
  cursor: pointer;
}

.Pie {
  position: absolute;
  top: 805px;
  left: 80px;
  width: 400px;
  height: 300px;
  z-index: 1;
}

.Pie2 {
  position: absolute;
  top: 805px;
  left: 480px;
  width: 400px;
  height: 300px;
  z-index: 1;
}

.rightSide {
  // position: absolute;
  // right: 0;
  // top: 0;
  // width: 600px;
  // background-color: #fff;
}

// wang

.main {
  width: 100%;
  height: calc(100% - 95px);
  display: flex;
  justify-content: space-between;
}

.map {
  padding-bottom: 17px;
  margin: 0 auto;
  // padding-bottom: 30px;
}

.border {
  background: linear-gradient(to left, #2ec7c9, #2ec7c9) left top no-repeat,
    linear-gradient(to bottom, #2ec7c9, #2ec7c9) left top no-repeat,
    linear-gradient(to left, #2ec7c9, #2ec7c9) right top no-repeat,
    linear-gradient(to bottom, #2ec7c9, #2ec7c9) right top no-repeat,
    linear-gradient(to left, #2ec7c9, #2ec7c9) left bottom no-repeat,
    linear-gradient(to bottom, #2ec7c9, #2ec7c9) left bottom no-repeat,
    linear-gradient(to left, #2ec7c9, #2ec7c9) right bottom no-repeat,
    linear-gradient(to left, #2ec7c9, #2ec7c9) right bottom no-repeat;
  background-size: 1px 20px, 20px 1px, 1px 20px, 20px 1px;
  padding: 10px;
}

.title {
  position: relative;
  height: 75px;
  font-size: 38px;
  text-align: center;
  color: #347afc;
  background: url("../../assets/image/quantou.png") center center no-repeat;
  z-index: 9;

  &Wrapper {
    position: absolute;
    top: 30px;
    width: 100%;
  }
}
.box1 {
  @extend .border;
  width: 400px;
  margin: 0px 10px;
}
.shuxing,
.shouru,
.zhichu {
  @extend .border;
  width: 500px;
  margin: 0 20px;
}
.teacher {
  @extend .border;
  width: 860px;
  margin: 20px;
}

.left {
  position: relative;
  //top: -50px;
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.center {
  flex: 1;
  padding: 20px 20px 0px 20px;
}
.right {
  position: relative;
  //top: -50px;
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.statistics {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  @include mobile {
    width: 100%;
  }
  .item {
    flex: 1;
    text-align: center;
  }
  .label {
    color: #ccc;
    font-size: 20px;
  }
  .number {
    color: #347afc;
    font-size: 50px;
    line-height: 50px;
  }
  span {
    font-size: 24px;
    margin-left: 10px;
  }
}

.basic {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  .item {
    flex: 1;
    text-align: center;
  }
  .label {
    color: #ccc;
    font-size: 16px;
  }
  .number {
    color: #347afc;
    font-size: 35px;
  }
  span {
    font-size: 16px;
    margin-left: 10px;
  }
}

.major {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  .item {
    flex: 1;
    text-align: center;
  }
  .label {
    color: #ccc;
    font-size: 16px;
  }
  .number {
    color: #2ec7c9;
    font-size: 35px;
  }
  span {
    font-size: 16px;
    margin-left: 10px;
  }
  @include mobile {
    display: block;
  }
}

.buttons {
  border: 2px solid #2ec7c9;
  display: flex;
  width: 400px;
  margin: 0 auto;
  box-shadow: inset 0px 0px 27px -4px #2ec7c9;
}
.button {
  flex: 1;
  color: #2ec7c9;
  text-align: center;
  padding: 8px;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover,
  &.active {
    background-color: #2ec6c9be;
    color: #fff;
  }
}

.heading {
  position: relative;
  width: 1920px;
  height: 75px;
  padding-top: 20px;
  font-size: 40px;
  color: #2ec7c9;
  text-align: center;
  background: url("../../assets/image/pagetitle.jpg") center center no-repeat;
}

.subHeading {
  padding: 10px;
  font-size: 20px;
  color: #2ec7c9;
  text-align: center;
}

.table {
  margin: 0 auto;
  color: #ffffff;
  border-collapse: collapse;
  border-radius: 10px;

  tr,
  th,
  td {
    padding: 20px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 26px;
  }

  &Wrapper {
    table {
      width: 80vw;
      margin: 0 auto;
      color: #2ec7c9;
      border-collapse: collapse;
      border-radius: 10px;

      tr,
      th,
      td {
        padding: 20px 20px;
        border: 1px solid #2ec7c9;
        text-align: center;
      }
    }
  }

  .tl {
    text-align: left;
  }
}

.listTitle {
  padding: 30px 0 80px 0;
  text-align: center;
  color: #2ec7c9;
  font-size: 40px;
}

.tabs {
  display: flex;
  width: 400px;
  justify-content: space-between;
}

.tab {
  width: 180px;
  height: 47px;
  line-height: 47px;
  text-align: center;
  color: #15dbf5;
  font-size: 20px;
  font-weight: bold;
  background: url("../../assets/image/border.png") center center no-repeat;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #0098d9;
    color: #ffffff;
  }
}

.active {
  background-color: #0098d9;
  color: #ffffff;
}

.Nav {
  margin: 0 auto;
  transform: scale(0.7);

  &Wrapper {
    position: absolute;
    right: 0;
    bottom: 24px;
  }
}
.txtNum {
  font-size: 45px;
  font-weight: 500;
}
