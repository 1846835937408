.table {
  width: 100%;
  border-left: 1px rgba(233, 233, 233, 1) solid;
  border-top: 1px rgba(233, 233, 233, 1) solid;
  th {
    border: 1px rgba(233, 233, 233, 1) solid;
    font-weight: normal;
    background-color: rgba(56, 151, 217, 1);
    height: 45px;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }

  td {
    padding: 0 20px;
    font-size: 12px;
    height: 45px;
    border-bottom: 1px rgba(233, 233, 233, 1) solid;
    border-right: 1px rgba(233, 233, 233, 1) solid;
  }

  tr {
    background: rgba(249, 249, 249, 1);
  }

  tr:nth-child(2n){
    background: #f4f8fb;
  }
}


.center{
  text-align: center;
}

.gray{
  background: rgba(249, 249, 249, 1);
}

.blodFont {
  font-weight: bold;
}
