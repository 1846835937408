@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
  }
}

.container {
  height: 171px;
  width: 100%;
  background-color: rgba(0, 153, 255, 1);
  position: relative;
  @include mobile {
    height: auto;
  }
}

.tabs {
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  @include mobile {
    position: unset;
  }
}

.tab {
  cursor: pointer;
  display: inline-block;
  height: 40;
  background-color: #0060ca;
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #cccccc;
  border-radius: 5px 5px 0 0;
  @include mobile {
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    width: 100%;
  }
}

.current {
  background-color: #fff;
  font-weight: bold;
  color: rgb(0, 153, 255);
}

.title {
  padding-top: 20px;
  margin-left: 30px;
}

.school {
  font-size: 16px;
  color: #ffffff;
}

.schooltitle {
  font-size: 18px;
  color: #ffffff;
}

.professionaltitle {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  color: #ffffff;
  font-size: 32px;
  margin-top: 10px;
}

.tabContent {
  padding-bottom: 50px;
  background-color: #fff;
  width: 1300px;
  @include mobile {
    width: 100%;
    overflow: scroll;
  }
}

.titlebutton {
  position: absolute;
  right: 30px;
  top: 40px;
  Button {
    color: #0079fe;
    width: 140px;
    height: 40px;
    border-radius: 4px;
    font-family: "Arial Negreta", "Arial Normal", "Arial";
    font-weight: 700;
  }
  @include mobile {
    position: unset;
    margin: 10px 0;
    margin-bottom: 50px;
  }
}
