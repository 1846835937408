.root {
  padding-bottom: 65px;
}

.content {
  width: 100%;
  margin: 0 auto;
}

.contentHeader {
  width: 100%;
  height: 91px;
  line-height: 91px;
  padding: 0 20px;
  background: #0099ff;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
}

.container {
  overflow: hidden;
  padding: 35px 0;
  background: #ffffff;
  // border: 1px solid #e9e9e9;
  th{
    //font-weight: 700 !important;
    //color: #666 !important;
  }
  :global{
    .ant-table-thead > tr > th{
      //padding: 16px 10px;
    }
  }

}

.table {
  width:100%;
  :global {
    .ant-table-thead>tr>th {
      color: #333333;
      background: #f7f8fc!important;
    }
  }
}

.shallow_gray {
  color: #666666;
  background: #f7f8fc!important;
}

.deep_gray {
  color: #666666;
}


.customers
{
  width:100%;
  border-collapse:collapse;
  border: 1px solid #e9e9e9;
}

.customers td, .customers th
{
  max-width: 180px;
  font-size:12px;
  //padding:6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color:#666666;
}

.customers th
{
  padding:6px 0 6px 8px;
  font-size:12px;
  font-weight: bold;
  text-align:left;
  background-color:#f5f5f5;
  color:#666666;
}

.customers tr.alt td
{

  background-color:#fbfbfb;
}

.titlebutton{
  position: absolute;
  right: 30px;
  top: -10px;
  Button{
    color: #0079FE;
    width: 140px;
    height: 40px;
    border-radius: 4px;
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;

  }
}
