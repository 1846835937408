

.root{

}

.header{
  padding: 30px;
  background: rgba(0, 153, 255, 1);
}

.title{
  margin-bottom: 0;
  font-size: 32px;
  color: #fff;
  font-weight: bold;
}

.content{
  padding-bottom: 60px;
  border: 1px rgba(228, 228, 228, 1) solid;
  background: #fff;
}

.nav{
  margin-bottom: 20px;
  color: #fff;
}

.back{
  font-weight: bold;
  color: #fff;

  &:hover{
    color: inherit;
  }
}

.label{
  margin-top: 60px;
  padding-left: 44px;
  line-height: 40px;
  font-size: 14px;
  border-left: 7px rgba(0, 153, 255, 1) solid;
  color: #666;
  font-weight: bold;
}

.inner{
  padding: 0 50px;
}

.table{
  width: 100%;

  td{
    height: 40px;
    line-height: 1.2;
    padding: 5px 20px;
    border: 1px solid #cccccc;
    border-right: 0;
    border-bottom: 0;
    color: #666666;

    &:last-child{
      border-right: 1px solid #cccccc;
    }
  }

  tr:last-child td{
    border-bottom: 1px solid #cccccc;
  }

  tr:nth-child(2n){
    background: rgba(251, 251, 251, 1);
  }
}

.noBorder{
  border-left: 0 !important;
}
