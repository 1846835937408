@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
  @include mobile {
    width: 100%;
  }
}

.title {
  font-size: 24px;
  color: #666666;
}

.search {
  padding: 20px 20px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  @include mobile{
    height: auto;
  }
}

.blueButton {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.list {
  margin-top: 20px;
  background: #ffffff;
  :global {
    .ant-table-thead th.ant-table-column-sort {
      background: #2371a7;
    }
    .ant-table-thead th.ant-table-column-has-sorters:hover {
      background: #2371a7;
    }
  }
}

.tableContent tr th {
  background: #3897d9;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
}

.tableButton {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 80px;
  height: 32px;
  line-height: 30px;
  padding: 0;
  border-radius: 32px;
}
