.root{
  position: relative;
  margin: 0 auto;
  width: 1105px;
  background-color: #fff;
}

.wrapper{
  display: flex;
  border: 1px solid #e4e4e4;
}


.item{
  position: relative;
  padding: 15px 15px 0 15px;
  height: 170px;
  border-left: 1px solid #e4e4e4;
  flex: 0 0 221px;
  width: 221px;

  &:first-child{
    border-left: none;
  }
}

.itemSpecial{
  padding-left: 27px;
  padding-top: 40px;
}

.noInfo{
  padding: 10px 15px 0 15px;
  text-align: center;
}

.selectBtn{
  width: 98px;
  height: 36px;
  border: 1px solid #e4e4e4;
  background-color: #f0f2f5;
  color: #0099FF;;
  text-align: center;
  border-radius: 3px;
  box-shadow: none;

  &:hover{
    background-color: #0099ff;
    color: #ffffff;
  }
}

.btnWrapper{
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  text-align: center;
}

.header{
  font-size: 20px;
  color: #999999;
  font-weight: bold;
}

.description{
  font-size: 16px;
  color: #999;
}
.textOverFlow{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.school{
  color: #999999;
  font-size: 14px;
  padding-right: 20px;
  @extend .textOverFlow;
}

.major{
  padding-right: 20px;
  color: #999999;
  font-size: 14px;
  @extend .textOverFlow;
}

.course{
  padding-top: 6px;
  font-weight: bold;
  color: #2c7fb7;
  font-size: 22px;
  text-align: center;
  @extend .textOverFlow;

  a{
    color: inherit;
  }
}

.title{
  padding: 0 15px;
  height: 45px;



  &Wrapper{
    background-color: #3897d9;
    line-height: 45px;
    font-size: 14px;
    color: #ffffff;
  }
}

.addBtn{
  position: absolute;
  top: 0;
  right: -40px;
  padding: 0 12px;
  width: 40px;
  height: 170px;
  line-height: 18px;
  color: #ffffff;
  background-color: #0079FE;
  cursor: pointer;

  img{
    margin-top: 32px;
    margin-bottom: 15px;
  }
}

.table{
  width: 100%;
  table-layout: fixed;

  th, td{
    border: 1px solid #e4e4e4;
  }
}

.column{
  flex: 0 0 20%;
}

.detail{
  color: #0079FE;
  cursor: pointer;
}

.TName{
  padding: 0 20px;
  width: 220px;
  line-height: 40px;
  font-size: 12px;
  color: #2c7fb7;
  text-align: right;
}

.TNameColor{
  color: #666666;;
}

.TItem{
  padding: 5px 16px;
  width: 220px;
  line-height: 16px;
  font-size: 12px;
  color: #666666;
}

.fontStyle{
  font-size: 14px;
  color: #333333;
}

.fontStyle2{
  font-size: 14px;
  color: #666666;
}

.closeBtn{
  position: absolute;
  top: 0;
  right: 0;

  &:hover{
    cursor: pointer;
  }
}

.content{
  height: 352px;
  font-size: 14px;
  color: #333333;
  overflow: auto;
}

.main{
  overflow: auto;
}
