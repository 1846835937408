.root{
  position: relative;
}

.more{
  position: absolute;
  right: 0;
  padding: 0;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
  background: transparent;
}
