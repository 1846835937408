
.root{
  display: flex;
  justify-content: space-between;
}

.item{
  width: 180px;
  height: 80px;
  padding-left: 83px;
  padding-top: 10px;
  border: 2px #102a65 solid;
}

.icon1 {
  background: url("./img/icon1.png") 16px center no-repeat;
}

.icon2 {
  background: url("./img/icon2.png") 16px center no-repeat;
}

.icon3 {
  background: url("./img/icon3.png") 16px center no-repeat;
}

.icon4 {
  background: url("./img/icon4.png") 16px center no-repeat;
}

.title{
  font-size: 14px;
  color: #02d9fd;
}

.num{
  font-size: 22px;
  color: #ffffff;
}

.unit{
  font-size: 15px;
  color: #ffffff;
}
