.root{
  position: relative;
  background: rgba(85,85,242,.14);
  border-radius: 10px;
  margin: 0 20px 20px 20px;
}

.left{
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACOCAYAAADw82KEAAACD0lEQVQoUzWSeWjPcRjH3/9/jueHmK3Jlfuca4w5coWN2YzZ0W9mMxu/zW9bjhw5Qo5IJBIpKVkriUhKSpZoI0eOEEJDY6nliDfPt33/+fz16Xme9+v9gnHzCCOZhIlkEaZzNmG65BKmax5huuUTJqGQMIlRwiSVECa5lLA9ygnbs4KwvVYTtncVYfvGCduvlrD91xJ2wHrCDtpI2MGbCTt0K2GHbSfs8J2EG7mbcCl7CTt6P+HGHiTcuEOESz1CuAlHCZd2nHCTThAu/RThppwm3NQzhJt2lnDTzxFuxnnCz2wg/OwLhJ9zkfBzLxE+4wrhM68Rfv51wmfdIPzCm4TPuUX4RbcJv/gO4ZfcJXxeE+Hz7xO+4CEhRY8JiT4lpPg5IcteErL8NSGlbwlZ8Z6Q8o+ErPxESOUXQlZ9JSTWRkjVd0LWtBOR+E9Can8Tkbq/+vwhIjW/CIn/IKS6Pfwc+6YDWgmp+KxDW3THB0LK3uneN4SUvNIzXhA++kzve0JI4SM99wHhlzZrjnthotzGMGW25g2SL1AGAY2Mq0rocgcrF1Cb1aAk60OmAd2A82Qlnn6ScBO1hbRjhBuvzaQe1sq0rTEHCDdqH2FT9hB2xC7td4c2vY2wQ7aoApsIO3CDarFOBalTVWoI26dazYmpSJUdSpnkslCzxGLCdC8KJQx0DMTslBPKGmj7X+B/9doTrUYH9RsAAAAASUVORK5CYII=) 0 0 no-repeat;
  background-size: cover;
}

.right{
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAACOCAYAAADw82KEAAACD0lEQVQoUzWSeWjPcRjH3/9/jueHmK3Jlfuca4w5coWN2YzZ0W9mMxu/zW9bjhw5Qo5IJBIpKVkriUhKSpZoI0eOEEJDY6nliDfPt33/+fz16Xme9+v9gnHzCCOZhIlkEaZzNmG65BKmax5huuUTJqGQMIlRwiSVECa5lLA9ygnbs4KwvVYTtncVYfvGCduvlrD91xJ2wHrCDtpI2MGbCTt0K2GHbSfs8J2EG7mbcCl7CTt6P+HGHiTcuEOESz1CuAlHCZd2nHCTThAu/RThppwm3NQzhJt2lnDTzxFuxnnCz2wg/OwLhJ9zkfBzLxE+4wrhM68Rfv51wmfdIPzCm4TPuUX4RbcJv/gO4ZfcJXxeE+Hz7xO+4CEhRY8JiT4lpPg5IcteErL8NSGlbwlZ8Z6Q8o+ErPxESOUXQlZ9JSTWRkjVd0LWtBOR+E9Can8Tkbq/+vwhIjW/CIn/IKS6Pfwc+6YDWgmp+KxDW3THB0LK3uneN4SUvNIzXhA++kzve0JI4SM99wHhlzZrjnthotzGMGW25g2SL1AGAY2Mq0rocgcrF1Cb1aAk60OmAd2A82Qlnn6ScBO1hbRjhBuvzaQe1sq0rTEHCDdqH2FT9hB2xC7td4c2vY2wQ7aoApsIO3CDarFOBalTVWoI26dazYmpSJUdSpnkslCzxGLCdC8KJQx0DMTslBPKGmj7X+B/9doTrUYH9RsAAAAASUVORK5CYII=) 0 0 no-repeat;
  background-size: cover;
}

.top{
  display: block;
  width: 30%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI4AAAABCAYAAAAIG5YFAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACESURBVChTdU9LFoMwCIR06evJev/jCA4kk2I0C94wP4l6fH8urYnop0/sLXbif1dq9Jlf8eaX3i4/cvWmQveBd3/T4w5dY4fm4LP/1hn56s+3ior7KWqWKIZxA2KSD0wt8Kn5NvuCmaPW9bz7yGwQviMX7w0+b9f++p2F9/8kp8dc9Uwu94yYZBD14WsAAAAASUVORK5CYII=) 0 0 no-repeat;
  background-size: cover;
}

.bottom{
  display: block;
  width: 30%;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAI4AAAABCAYAAAAIG5YFAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACESURBVChTdU9LFoMwCIR06evJev/jCA4kk2I0C94wP4l6fH8urYnop0/sLXbif1dq9Jlf8eaX3i4/cvWmQveBd3/T4w5dY4fm4LP/1hn56s+3ior7KWqWKIZxA2KSD0wt8Kn5NvuCmaPW9bz7yGwQviMX7w0+b9f++p2F9/8kp8dc9Uwu94yYZBD14WsAAAAASUVORK5CYII=) 0 0 no-repeat;
  background-size: cover;
}
