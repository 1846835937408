
.root{
  display: flex;
  flex: 0;
  align-items: center;
  // white-space: nowrap;
  // padding: 20px 0px;
}

.editBtn{

}

.logout{
  color: #fff;
  cursor: pointer;
}
.logoutWrap{
  padding: 0px 20px;
}
