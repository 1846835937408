.root{
  margin: 0 auto;
  width: 1300px;
}

.container{
  height: 191px;
  width: 100%;
  background-color: rgba(0, 153, 255, 1);
  position: relative;
}

.tabs{
  padding: 0 30px;
  position: absolute;
  bottom: 0;
  :global{
    .ant-col-3{
      -ms-flex: 0;
      flex: 0;
      max-width: 15%;
    }
    // .ant-col-4{
    //   padding-left: 5px;
    //   padding-right: 5px;
    //   display: block;
    //   -ms-flex: 0 0 11%;
    //   flex: 0 0 11%;
    //   max-width: 11%;
    // }
  }
}

.tab{
  cursor:pointer;
  display: inline-block;
  background-color: #0060ca;
  width: 140px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #cccccc;
  border-radius: 5px 5px 0 0;
  &_one{
    display: block;
    width: 100px;
  }
  width: 100px
  &_line{
    display: block;
    width: 125px;
    line-height: 1.5em;
  }
}

.current{
  background-color: #fff;
  font-weight: bold;
  color: rgb(0, 153, 255);
}

.title{
  padding-top: 20px;
  margin-left:30px;
}

.schooltitle{
  font-size: 18px;
  color: #FFFFFF;
}

.professionaltitle{
  font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
  font-weight: 700;
  color: #FFFFFF;
  font-size: 32px;
  margin-top: 10px;
}

.school{
  font-size: 12px;
  font-weight: normal;
}

.major{
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: normal;
}

.tabContent{
  padding-bottom: 50px;
  background-color: #fff;
  width: 1300px;
}

.titlebutton{
  position: absolute;
  right: 30px;
  top: 40px;
  Button{
    color: #0079FE;
    width: 140px;
    height: 40px;
    border-radius: 4px;
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;

  }
}
