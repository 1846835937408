.table{
  width: 100%;
  margin: 0 auto;
  color: #999;
  border-collapse: collapse;
  border-radius: 10px;

  tr, th, td{
    padding: 12px 20px;
    text-align: center;
    font-size: 14px;
  }

  tr:nth-child(2n + 1) {
    background: #f7f8fc;
  }

  &Wrapper{
    height: 600px;
    background: #fff;
    border-radius: 16px;
    overflow: hidden;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #535353;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background   : #ededed;
    }
  }

  &Title{
    padding: 40px 50px;
    color: #ffffff;
    font-size: 24px;
    background: #367bfc;
  }
}

.input{
  color: #999;
  height: 25px;
  line-height: 25px;
  display: inline-block;
  margin-left: 20px;
  outline: none;
  border: none;
  padding-bottom: 5px;
  width: 280px;
  font-size: 16px;
}
::-webkit-input-placeholder { /* WebKit browsers */
  color: #999;
  font-size: 16px;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #999;
  font-size: 20px;
}

:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #999;
  font-size: 20px;
}
.tableService{
  tr, th, td{
    padding: 20px 5px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 20px;
  }
}

.wrapper{
  height: calc(600px - 117px);
  overflow: auto;
}

.tableList {
  //position: relative;
  //width: 100%;
  //height: 480px;
  //display: block;
  //overflow-y: scroll;
}

.active{
    text-decoration: underline ;
    cursor: pointer;
    color: blue;
}
.btn{
  position: absolute;
  top: 50px;
  right: 30px;
}

.root {

  //canvas {
  //  transform: scale(0.8) translate(-119px, -60px);
  //}
}
.buttonStyle{
  width: 65px;
  height: 34px;
  background: #40a9ff;
  border-color: #40a9ff;
  color: #fff;
  outline: none;
}
.title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #367bfc;
}
.table{
  width: 100%;
  margin: 0 auto;
  color: #999;
  border-collapse: collapse;
  border-radius: 10px;

  tr, th, td{
    padding: 12px 20px;
    text-align: center;
    font-size: 14px;
  }

    &tableList{
      height: 300px;
    }

  tr:nth-child(2n + 1) {
    background: #f7f8fc;
  }

  &Wrapper{
    background: #fff;
    border-radius: 16px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #535353;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background   : #ededed;
    }
  }

  &Title{
    color: #ffffff;
    font-size: 24px;
    background: #367bfc;
    padding: 40px 50px;
  }
}

.tableScroll{
  height: 480px;
  overflow-y: auto;
}

.tableShadow{
  box-shadow: 1px 1px 20px -5px #948e8e;
}

.tableService{
  tr, th, td{
    padding: 20px 5px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 20px;
  }
}

.wrapper{
  height: calc(600px - 117px);
  overflow: auto;
}



