.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
}

.contentHeader {
  padding: 30px 0 30px 20px;
  width: 100%;
  background: #0099ff;
  color: #ffffff;
}

.headerSmallTitle {
  display: block;
  font-size: 18px;
}

.headerBigTitle {
  display: block;
  font-size: 32px;
  font-weight: bold;
}


