@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
  }
}

.container {
  height: 171px;
  width: 100%;
  background-color: rgba(56, 151, 217, 1);
  position: relative;
  @include mobile {
    height: auto;
  }
}

.tabs {
  padding: 0 60px;
  position: absolute;
  bottom: 0;
}

.tab {
  cursor: pointer;
  display: inline-block;
  background-color: #2c7fb7;
  width: 140px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  text-align: center;
  color: #e4e4e4;
  border-radius: 5px 5px 0 0;
}

.current {
  background-color: #fff;
  font-weight: bold;
  color: rgb(230, 80, 67);
}

.title {
  padding-top: 20px;
  margin-left: 30px;
}

.schooltitle {
  font-size: 18px;
  color: #ffffff;
}

.professionaltitle {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  color: #ffffff;
  font-size: 32px;
  margin-top: 10px;
}
.customersBox {
  padding: 0 40px;
  @include mobile {
    overflow: auto;
  }
}
.tabContent {
  padding-bottom: 50px;
  background-color: #fff;
  width: 1300px;
  @include mobile {
    width: 100%;
  }
}

.titlebutton {
  position: absolute;
  right: 25px;
  top: 40px;
  Button {
    color: #0079fe;
    width: 140px;
    height: 40px;
    border-radius: 4px;
    font-family: "Arial Negreta", "Arial Normal", "Arial";
    font-weight: 700;
    
  }
  @include mobile {
    position: inherit;
  }
}
