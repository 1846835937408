@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;

  @include mobile {
    width: auto;
  }
}
.tableContent {
  width: 100%;
  background-color: #fff;
  padding-bottom: 80px;
}
.tableContent2{
  width: 100%;
  background-color: #fff;
}
.tableTitle {
  margin: 25px 0 0 0;
  padding: 0 20px;
  height: 80px;
  line-height: 80px;
  font-size: 24px;
  h1 {
    color: #666666;
  }
  @include mobile {
    font-size: 22px;
  }
}
.reachTable{
  padding: 0;
  background-color: #fff;
  :global{
    .ant-table-thead > tr > th{
      padding:5px !important;

    }
    .ant-table-tbody > tr > td{
      padding:5px !important;

    }
  }
  
    
  
}
.table {
  padding: 0 20px;

  @include mobile {
    padding: 0;
  }

  :global {
    .ant-table-thead > tr > th {
      color: #333333;
      background: #f7f8fc !important;
    }
  }
}

.shallow_gray {
  color: #666666;
  background: #f7f8fc !important;
}

.deep_gray {
  color: #666666;
}

