.root {
  background: #fff;
  border-radius: 16px;
}

.title {
  padding: 10px 20px;
  color: #ffffff;
  font-size: 18px;
  background: #367bfc;
}

.table {
  width: 100%;
  margin: 0 auto;
  color: #999;
  border-collapse: collapse;
  border-radius: 10px;
}
.thead {
  position: relative;
  width: 100%;

  tr,
  th,
  td {
    padding: 12px 5px;
    text-align: center;
    font-size: 14px;
    background: #f7f8fc;
  }
}
.tableList {
  position: relative;
  width: 100%;

  tr,
  th,
  td {
    padding: 12px 5px;
    text-align: center;
    font-size: 14px;
  }

  tr:nth-child(2n) {
    background: #f7f8fc;
  }
}

.main {
  height: 500px;
  overflow: hidden;
  text-align: center;
  color: #999;
}
.header {
  padding: 12px 5px;
  text-align: center;
  font-size: 14px;
  background: #f7f8fc;
  font-weight: bold;
}
.body {
  width: 100%;
  height: 435px;
  overflow: scroll;
  :global {
    .slick-slide {
      color: rgba(255, 255, 255, 0.8);
      background: #fff;
      &:nth-child(2n) {
        background: #f7f8fc;
      }
    }
  }
}
.item {
  padding: 12px 5px;
  text-align: center;
  font-size: 14px;
}
.main > ::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
}
/*定义滚动条轨道 内阴影+圆角*/
.main > ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(182, 182, 182, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
.main > ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(182, 182, 182, 0.3);
  background-color: rgb(157, 157, 157);
}
.itemBox {
  width: 100%;
  display: flex !important;
}
