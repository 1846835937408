.root {
    padding-bottom: 65px;
  }

.content {
    width: 1300px;
    margin: 0 auto;
}

.search {
    padding: 20px 20px;
    width: 100%;
    height: 72px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
}

.contentList {
    margin-top: 20px;
    padding: 20px 30px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    position: relative;
}

.title {
    font-size: 24px;
    color: #666;
    font-weight: 409;
}

.tip{
    color: #999;
}


