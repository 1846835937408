
.root{
  flex: 1;
}

@mixin btn{
  width: 83px;
  border: 0;
  font-size: 12px;
  height: 30px;
  vertical-align: middle;
}

.left{
  @include btn;
  border-radius: 5px 0 0 5px;
  color: #fff;
  background-image: linear-gradient(to right, #0255FD, #367BFC);
  &:hover{
    color: #fff;
    background-image: linear-gradient(to right, #0255FD, #367BFC);
  }
}

.right{
  @include btn;
  border-radius: 0 5px 5px 0 ;
  border: 2px solid #367BFC;
  &:hover{
    color: inherit;
    border: 2px solid #367BFC;
  }
}
