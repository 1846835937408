.root {
  position: relative;
  color: #fff;
}
.statistic {
  border-radius: 8px;
  background-color: #08c;
  padding: 10px 20px;
  color: #000;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: url("images/bg.png") no-repeat;
  background-position: right bottom;
}
.pointer {
  cursor: pointer;
}

.text {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 24px;
}

.number {
  font-size: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 28px;
  font-weight: bold;
}
.desc {
  font-size: 14px;
}

.color0 {
  background-color: rgba(242, 242, 242, 1);
  color: #666666;
  text-align: center;
}

.color1 {
  background-color: #e2ebff;
}

.color2 {
  background-color: #e6e2ff;
}

.color3 {
  background-color: #e2f2ff;
}

.color4 {
  background-color: #fff0e2;
}

.color5 {
  background-color: #eff2f7;
}

.remove {
  position: absolute;
  // background-color: #ccc;
  border-radius: 50%;
  width: 13px;
  height: 14px;
  line-height: 10px;
  right: 11px;
  top: 8px;
  z-index: 1;
  cursor: pointer;
  text-align: center;
  transition: 0.2s ease-in-out;
  &:hover {
    background-color: #ff0000;
  }
}
