.root {
}
.header {
  display: flex;
  height: 63px;
  line-height: 63px;
  width: 100%;
  background: #fff;
  p {
    color: #999;
    font-weight: bold;
    font-size: 20px;
  }
  p:nth-of-type(1) {
    margin-right: 10px;
    margin-left: 20px;
  }
  p:nth-of-type(3) {
    margin-left: 10px;
  }
  .active {
    color: #347afc !important;
  }
}
.chartCard {
  :global {
    .ant-card-body {
      padding: 24px 0;
    }
  }
}
.marBtm20 {
  margin-bottom: 20px;
}
