.root{
  background: #A00B0A;

  img{
    max-width: 100%;
  }
}

.wrapper{
  margin: 0 auto;
  width: 1300px;
}
