@import "src/components/Style/mixin";
.root {
  flex: 1;
  padding-top: 193px;
  @include mobile{
    display: none;
  }
}

.item {
  display: inline-block;
  margin-right: 80px;
  margin-bottom: 50px;
  padding-top: 30px;
  width: 140px;
  height: 140px;
  border: 1px rgba(219, 219, 219, 1) solid;
  background: #fff;
  border-radius: 100%;
  text-align: center;
  color: #666;
  font-size: 16px;
  font-weight: 700;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑", serif;

  svg {
    display: block;
    margin: 0 auto 20px;
    font-size: 48px;

  }
  .iconItem {
    display: block;
    margin: 0 auto 12px;
    width: 50px;
  }
}
.top {
  display: flex;
  justify-content: center;
}
