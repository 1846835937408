@import "src/components/Style/mixin";
.root {
  flex: 0;
  flex-basis: 420px;
  height: 550px;
  padding-top: 50px;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  @include mobile {
    height: auto;
    padding-top: 130px;
  }
}

.pic {
  height: 170px;
  width: 170px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  border-radius: 100%;
  text-align: center;
  font-size: 24px;
  color: #666;
  background: rgb(242, 242, 242);

  svg {
    font-size: 64px;
  }
}

.form {
  margin-top: 60px;
  margin-left: 66px;
  margin-right: 66px;
  @include mobile {
    margin: 66px;
  }
  position: relative;

  :global {
    .ant-input-prefix {
      font-size: 24px;
      color: #cccccc;
    }

    .ant-input-affix-wrapper {
      padding: 10px 11px;
    }

    .ant-input {
      padding: 10px 11px;
    }
  }
}

.submit {
  width: 100%;
  height: 50px;
  font-size: 18px;
  border-radius: 4px;
}
.codeImg {
  width: 40%;
  position: absolute;
  right: 0;
  bottom: 80px;
}
