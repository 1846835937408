
.root{
  :global{
    .slick-slide {

      margin-bottom: 0;
      padding: 0 10px;
      line-height: 50px;
      color: rgba(255, 255, 255, 0.8);
      background: #0a2732;

      &:nth-child(2n) {
        background: #003b51;
      }

    }
  }
}

.header{
  line-height: 50px;
  display: flex;
  color: #fff;
  background: #00baff;

  &>div{
    flex: 1;
    flex-basis: 0;
    padding: 0 30px;

    &:last-child{
      flex: 0.3;
    }
  }
}

.item{
  display: flex;

  &>div{
    flex: 1;
    flex-basis: 0;
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;

    &:last-child{
      flex: 0.3;
    }
  }
}
