@import "src/components/Style/mixin";

.root {
  //canvas {
  //  transform: scale(0.8) translate(-119px, -60px);
  //}
}

.table {
  width: 100%;
  margin: 0 auto;
  color: #999;
  border-collapse: collapse;
  border-radius: 10px;
  @include mobile {
    white-space: nowrap;
  }
  tr,
  th,
  td {
    padding: 12px 20px;
    text-align: center;
    font-size: 14px;
  
  }

  tr:nth-child(2n + 1) {
    background: #f7f8fc;
  }

  &Wrapper {
    height: 100px;
    background: #fff;
    border-radius: 16px;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
  }

  &Title {
    padding: 40px 50px;
    color: #ffffff;
    font-size: 24px;
    background: #367bfc;
    @include mobile {
      padding: 30px;
    }
  }
}

.tableService {
  tr,
  th,
  td {
    padding: 20px 5px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 20px;
  }
}

.wrapper {
  height: calc(600px - 117px);
  overflow: auto;
}

.tableList {
  //position: relative;
  //width: 100%;
  //height: 480px;
  //overflow-y: scroll;
  //height: 100px !important;
}
