@import "src/components/Style/mixin";
.vocationRow {
  // width: 971px;
  border: 1px solid #e9e9e9;
}

.rowTitle {
  padding-left: 20px;
  width: 100%;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px solid #e9e9e9;
  background: #f7f7f7;
  font-size: 14px;
}

.sortList {
  padding: 10px;
}

.sortRow {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e9e9e9;
}

.sortRowTitle {
  width: 25%;
  font-size: 12px;
  color: #666666;
}

.sortRowList {
  overflow: auto;
  width: 100%;
}

.sortRowListRow {
  float: left;
  font-size: 12px;
  width: 33.3%;
  @include mobile {
    width: 90%;
    padding-left: 10%;
  }
}

.antButton {
  // width: 100%;
  // padding: 0 5px;
  // max-width: 180px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
}

.antButton > span {
  display: inline;
  color: #6c97d2;
  width: 400px; /*要显示文字的宽度*/
  text-overflow: ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  white-space: nowrap; /*让文字不换行*/
  overflow: hidden; /*超出要隐藏*/
}
