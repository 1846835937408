@import "src/components/Style/mixin";

.root {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(1, 11, 60, 0.8);
  z-index: 9;
}

.childrenWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-44%, -50%);
  @include mobile {
    transform: translate(-50%, -50%);
    height: 80vh;
  }
}

.position {
  //margin-left: -50%;
}

.closeBtn {
  position: absolute;
  top: 13px;
  right: 15px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 91;
  background: url("./img/close.png") no-repeat;
  background-size: contain;
}
