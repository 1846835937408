@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.content {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
  }
}

.space {
  display: inline-block;
  margin-left: 20px;
  margin-right: 20px;
  color: #489df7;
}
.waper {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.summaryMap {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.list {
  margin-top: 20px;
}

.table {
  :global {
    .ant-table-thead > tr > th {
      color: #333333;
      background: #f7f8fc !important;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
      border-color: #dfdfdf;
    }
  }
}

.shallow_gray {
  color: #666666;
  background: #f7f8fc !important;
}

.deep_gray {
  color: #666666;
}

.list {
  width: 70%;
  background: #ffffff;
  position: fixed;
  top: 40%;
  left: 55%;
  z-index: 333;
  transform: translate(-50%, -30%);
  @include mobile {
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.chart14 {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 70px;
  color: #347afc;
  font-size: 20px;
  font-weight: bold;
  @include mobile {
    margin-left: 0;
  }
}

.table {
  width: 100%;
  margin: 0 auto;
  color: #999;
  border-collapse: collapse;
  border-radius: 10px;
  @include mobile {
    white-space: nowrap;
  }
  tr,
  th,
  td {
    padding: 12px 20px;
    text-align: center;
    font-size: 14px;
   
  }

  .tableList {
  }

  tr:nth-child(2n + 1) {
    background: #f7f8fc;
  }

  &Wrapper {
    background: #fff;
    border-radius: 16px;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    }
  }

  .Title {
    color: #ffffff;
    font-size: 24px;
    background: #367bfc;
    padding: 40px 50px;
    @include mobile {
      padding: 30px;
    }
  }
}
.closeButton {
  height: 100px;
  width: 100px;
  //background-color: #FA6900;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}
.closeButton:after {
  position: absolute;
  content: "\d7";
  font-size: 30px;
  color: white;
  font-weight: bold;
  left: 69%;
  top: 14%;
  transform: translate(-69%, -14%);
}
.waper1 {
  @include mobile {
    padding: 10px 28px 0px 28px;
  }
}
.mainBox {
  padding: 0 20px;
}
