@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;
}

.picShow {
  height: 568px;
}

.picLeft {
  background-color: white;
  height: 100%;
}

.picRight {
  position: absolute;
  height: 275px;
  width: 100%;
  background-color: white;
}

.picBottom {
  height: 275px;
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.picPillar {
  height: 275px;
}

.number {
  font-size: 20px;
}
.itemLast {
  width: 420px;
}
.flex {
  display: flex;
}
.wid420 {
  width: 420px;
}
.mar20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mapBox {
  width: 100%;
  height: 650px;
  padding: 30px;
  background: #fff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  overflow: hidden;
  @include mobile {
    height: 350px;
    padding: 0;
    padding-top: 45px;
  }
}
.mapBox1 {
  margin-bottom: 40px;
  margin-top: 20px;
  padding: 30px;
  background: #fff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  @include mobile {
    margin-bottom: 20px;
  }
}
.chartBox {
  margin: 20px 20px 40px 20px;
  padding: "30px";
  background: "#fff";
  border: "1px solid #e9e9e9";
  box-sizing: "border-box";
}
.marTopMinus20 {
  margin-top: -20px;
}
