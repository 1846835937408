@import "src/components/Style/mixin";

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(1, 11, 60, 0.8);
  z-index: 9;
}

.childrenWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @include mobile {
    width: 80vw;
  }
}

.position {
  //margin-left: -50%;
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: -65px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 99;
  background: url("./img/close.png") no-repeat;
  @include mobile {
    right: 8px;
    background-size: 65%;
  }
}
