.root{

}

.nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  text-align: right;
  background: #fff;
}

.button{
  border: 0;
  box-shadow: none;
}

.logo{
  flex: 0;
  flex-basis: 350px;
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  padding: 13px 0 13px 50px;
  background: url(../../img/logo.png) no-repeat center left;
  margin-right: 15px;
  text-align: left;
}

.banner{
  &>div{
    width: auto;
  }

  :global{
    .slick-dots{
      bottom: -10px;
    }
  }
}
