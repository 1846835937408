
.root{
  display: flex;
}

.left{
  flex-basis: 229px;
  background: #cbe4fd;
  border-radius: 0 24px 0;
  border-top: 1px #91a3cd solid;
  border-right: 1px #91a3cd solid;
  overflow: hidden;
}

.middle{
  display: none;
  flex-basis: 260px;
  color: #333;
  font-size: 16px;
}

.right{
  flex: 1;
  margin-left: 20px;
  padding-right: 20px;
  overflow: auto;
  min-height: 400px;
  max-height: 500px;
  padding-bottom: 30px;
}

.right > div{

}

.title{
  position: relative;
  margin-bottom: 0;
  line-height: 65px;
  padding-left: 70px;
  font-size: 18px;
  color: #666;
  cursor: pointer;
  border-bottom: 1px #8cbceb solid;
  border-top:  2px #fff solid;

  &:first-child{
    border-top-color: transparent;
  }

  &:before{
    position: absolute;
    content: "";
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 30px;
    background-position-x: 0;
  }

  &.active{
    color: #f4f8fb;
    background: #2a4d9e url("img/bg.png") center center/cover no-repeat;

    &:before{
      background-position-x: -43px;
    }
  }
}


.block1{

  .left{
    flex-basis: 260px;
  }

  .title:before{
    background-position-x: 0;
    background-image: url("img/icon1.png");
  }

  .title.active:before{
    background-position-x: -35px;
  }

  .title:nth-child(1):before{
    background-position-y: 0;
  }

  .title:nth-child(2):before{
    background-position-y: -60px;
  }

  .title:nth-child(3):before{
    background-position-y: -123px;
  }

  .title:nth-child(4):before{
    background-position-y: -205px;
  }

  .title:nth-child(5):before{
    background-position-y: -274px;
  }
}


.block2{
  .left{
    flex-basis: 360px;
  }

  .title:before{
    height: 34px;
    background-position-x: 0;
    background-image: url("img/icon2.png");
  }

  .title.active:before{
    background-position-x: -44px;
  }

  .title:nth-child(1):before{
    background-position-y: 3px;
  }

  .title:nth-child(2):before{
    background-position-y: -50px;
  }

  .title:nth-child(3):before{
    background-position-y: -103px;
  }

  .title:nth-child(4):before{
    background-position-y: -153px;
  }

  .title:nth-child(5):before{
    background-position-y: -205px;
  }

  .title:nth-child(6):before{
    background-position-y: -255px;
  }
}


.block3{

  .title:before{
    background-position-x: 0;
    background-image: url("img/icon3.png");
  }

  .title.active:before{
    background-position-x: -43px;
  }

  .title:nth-child(1):before{
    background-position-y: 0;
  }

  .title:nth-child(2):before{
    background-position-y: -69px;
  }

  .title:nth-child(3):before{
    background-position-y: -133px;
  }

  .title:nth-child(4):before{
    background-position-y: -205px;
  }

  .title:nth-child(5):before{
    background-position-y: -274px;
  }
}
