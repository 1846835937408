@import "src/components/Style/mixin";
.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 30px;
  @include mobile {
    width: 100%;
  }
}

.courseSearch {
  width: 1300px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  padding: 30px;
  margin-top: 20px;
  @include mobile {
    width: 100%;
  }
}

.search {
  width: 100%;
  height: 72px;
  @include mobile {
    height: auto;
  }
}

.pkBtn {
  border: 1px solid #1890ff;
  color: #1890ff;
  width: 180px;
  height: 40px;
  border-radius: 3px;
  span:first-child {
    font-weight: 700;
    padding-right: 15px;
  }
  @include mobile {
    margin-top: 20px;
  }
}

.list {
  margin-top: 20px;
  padding: 20px 20px 60px 20px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.listTitle {
  font-size: 16px;
  color: #666;
  @include mobile {
    padding-top: 30px;
  }
}

.schoolList {
  margin-top: 5px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
}

.keyList {
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: center;
}

.schoolRow {
  width: 25%;
  float: left;
  height: 36px;
  line-height: 36px;
  // overflow: hidden;
  white-space: nowrap;
  // text-overflow: ellipsis;
  padding-right: 45px;
  @include mobile {
    width: 50%;
  }
}

.schoolRow:after {
  content: "";
  width: 25%;
  height: 0px;
  visibility: hidden;
}

.antButton {
  padding: 0 5px;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.antButton > span {
  display: inline;
  color: #6c97d2;
}

.searchTitle {
  font-family: "Arial Normal", "Arial";
  font-weight: 400;
  font-size: 24px;
  color: #666666;
}

.searchType {
  height: 40px;
  font-size: 14px !important;
}

.searchBtn {
  margin-right: 20px;
  background-color: rgba(0, 121, 254, 1);
  border-radius: 3px;
  height: 40px;
  width: 90px;
}

.hotSearch {
  width: 100%;
  margin-top: 20px;
  :global {
    .ant-col-5 {
      max-width: 20%;
    }
  }
}

.keyWords {
  cursor: pointer;
  height: 46px;
  line-height: 46px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}
.color1 {
  background-color: #0e75d4;
}
.color2 {
  background-color: #f0552f;
}
.color3 {
  background-color: #80b81e;
}
.color4 {
  background-color: #fdb92b;
}
.color5 {
  background-color: #13a2eb;
}
