@import "src/components/Style/mixin";
.root {
  padding-bottom: 65px;
}
.content {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
    overflow: hidden;
  }
}

.summaryMap {
  width: 100%;
  // height: 700px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 50px;
  display: flex;
  @include mobile {
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    padding-top: 26px;
  }
}

.totalMap {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 28px;
  padding-right: 96px;
  @include mobile {
    padding: 12px;
    padding-right: 12;
  }
}

.statistics {
  .item {
    line-height: 1;
    font-size: 20px;
    color: #666666;
    padding-bottom: 15px;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    &:last-of-type {
      padding: 0;
    }
    div {
      width: 285px;
      height: 60px;
      line-height: 60px;
      // background-size: contain;
      background-repeat: no-repeat;
      text-align: end;
      padding-right: 50px;
      padding-bottom: 20px;
    }
    .icon {
      position: absolute;
      left: 14px;
      top: 20px;
    }
    .txt {
      position: absolute;
      left: 32px;
      font-size: 13px;
      font-family: cursive;
      font-weight: bold;
    }
    .Go {
      position: absolute;
      right: 14px;
      top: 20px;
    }
    .one {
      position: relative;
      background: url("./img/one-2.png");
      background-blend-mode: lighten;
    }
    .two {
      position: relative;
      background: url("./img/two-2.png");
      background-size: cover;
    }
    .three {
      position: relative;
      background: url("./img/three-1.png");
      background-size: cover;
    }
    .four {
      position: relative;
      background: url("./img/four-1.png");
      background-size: cover;
    }
    .five {
      position: relative;
      background: url("./img/6-1.png");
    }
    .six {
      position: relative;
      background: url("./img/6.png");
    }
    .text {
      margin-left: 10px;
    }
    span {
      font-size: 25px;
      color: #fff;
      margin-left: 10px;
    }
  }
}

.map {
  flex: 1;
  @include mobile {
    margin-top: 20px;
    width: 100%;
  }
}

.title {
  color: #347afc;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 28px;
}
