@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
  @include mobile {
    width: 100%;
  }
}

.contentList {
  margin-top: 20px;
  padding: 20px 30px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  position: relative;
  @include mobile {
    margin-top: 0;
    margin-bottom: 20px;
    padding: 20px;
  }
}

.title {
  font-size: 24px;
  color: #666;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-weight: 700;
}
.txt {
  margin-left: 10px;
  color: #4d4d4d;
  font-size: 12px;
  @include mobile {
    margin-left: 0px;
  }
}

.headTitle {
  line-height: 40px;
  margin: 30px 0 10px 0;
  display: inline-block;
  p {
    margin-left: 20px;
  }
}

.blueLine {
  height: 40px;
  width: 7px;
  background-color: #0099ff;
  display: block;
  position: absolute;
  left: 0px;
}

.childTitle {
  color: #666;
  font-weight: 700;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-weight: 700;
  font-size: 14px;
  margin-left: 20px;
}

.ewmTitle {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  color: #666;
  margin-top: 15px;
}

.ewm {
  display: inline-block;
  margin: 0 20px;
}

.wzTitle {
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  color: #666;
  margin-top: 10px;
  p {
    color: #666;
    font-weight: normal;
    margin: 15px 0;
  }
  Link {
    font-weight: normal;
  }
}

.downBtn {
  position: absolute;
  right: 50px;
  width: 120px;
  height: 30px;
  color: #8c8c8c;
  border-radius: 4px;
  @include mobile {
    right: 30px;
  }
}

.col {
  border: solid 1px rgba(242, 242, 242, 1);
}

.picTitle {
  text-align: center;
  color: #666;
  font-family: "Arial Negreta", "Arial Normal", "Arial";
  font-weight: 700;
  font-size: 16px;
  line-height: 80px;
}

.flex {
  display: flex;
  justify-content: left;
  align-items: baseline;
  @include mobile {
    display: block;
  }
}
.titleNew {
  font-size: 20px;
  color: #333;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-weight: 700;
  @include mobile {
    display: contents;
  }
}
.titleBlue {
  margin: 20px 0;
  font-size: 20px;
  color: #347afc;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-weight: 700;
  @include mobile {
    padding-top: 20px;
  }
}
.ewmNew {
  margin-right: 45px;
  display: inline-block;
  box-shadow: 2px 2px 10px 2px #e7eefa;
  img {
    display: block;
    margin: auto;
    @include mobile {
      padding: 20px;
    }
  }
  @include mobile {
    display: block;
    width: 100%;
  }
}
.showBox {
  margin-top: 20px;
  padding: 0 30px 30px 30px;
  margin-right: 45px;
  width: 100%;
  box-shadow: 2px 2px 10px 2px #e7eefa;
  @include mobile {
    padding: 0 15px 30px 15px;
  }
}

.txtCer {
  font-family: "Adobe 黑体 Std";
  text-align: center;
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  @include mobile {
    margin-top: 20px;
  }
}
.txtCer1 {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  text-align: center;
  font-size: 20px;
  color: #333;
}
.item1 {
  width: 335px;
  height: 120px;
  border-radius: 10px;
  background: linear-gradient(to right, #5f72fc, #bb7cfe);
  color: #fff;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  box-shadow: 0px 19px 12px -12px #7975fd;
}
.item2 {
  width: 335px;
  height: 120px;
  border-radius: 10px;
  background: linear-gradient(to right, #3da5f6, #6e81fb);
  color: #fff;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  box-shadow: 0px 19px 12px -12px #4d99f8;
}
.item3 {
  width: 335px;
  height: 120px;
  border-radius: 10px;
  background: linear-gradient(to right, #ea6373, #ef9763);
  color: #fff;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  box-shadow: 0px 19px 12px -12px #eb746e;
}
.itemChild2 {
  width: 1px;
  height: 80px;
  background: #ffffff;
}
.flexArd {
  display: flex;
  justify-content: space-around;
}
.bigTxt1 {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-size: 36px;
  font-weight: bold;
  @include mobile {
    font-size: 28px;
  }
}
.bigTxt2 {
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
  font-size: 14px;
  @include mobile {
    font-size: 12px;
  }
}
.marAut {
  font-size: 14px;
  margin: auto;
}
.titleNew1 {
  font-size: 20px;
  color: #666;
  font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑";
}
.marTp60 {
  margin-top: 60px;
}
.abs80 {
  width: 100%;
  position: absolute;
  top: 90%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 12px;
}
