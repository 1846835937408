@import "src/components/Style/mixin";

.root {
  margin-bottom: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  padding: 10px 28px 28px 28px;
}

.title {
  color: #347afc;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
  @include mobile{
    padding: 10px 28px 0px 28px;
  }
}

.waper1 {
  @include mobile{
    padding: 10px 28px 0px 28px;
  }
}
