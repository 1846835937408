@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.logo{
  width: 100%;
  text-align: center;
}

.content {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
  }
}
.title {
  color: #347afc;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}
.summaryMap {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.chart3 {
  margin-top: 30px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.list {
  margin-top: 20px;
}
.table {
  :global {
    .ant-table-thead > tr > th {
      color: #333333;
      background: #f7f8fc !important;
    }
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
      border-color: #dfdfdf;
    }
  }
}

.shallow_gray {
  color: #666666;
  background: #f7f8fc !important;
}

.deep_gray {
  color: #666666;
}

.bar {
  overflow: auto;
  height: 270px;
  &::-webkit-scrollbar {
    display: none;
  }
}
