@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1300px;
  @include mobile {
    width: 100%;
    height: auto;
  }
}

.picShow {
  height: 850px;
  overflow: hidden;
  @include mobile{
    height: auto;
  }
}

.picLeft {
  background-color: white;
  height: 100%;
}

.picRight {
  position: absolute;
  height: 275px;
  width: 100%;
  background-color: white;
}

.picBottom {
  height: 275px;
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.img {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}
.picPillar {
  height: 275px;
}

.number {
  font-size: 20px;
}

.title {
  padding: 35px 30px 20px 30px;
  background-color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 1;
  color: #666666;
}

.content {
  margin: 0 auto;
  width: 1300px;
}

.summaryMap {
  width: 100%;
  height: 700px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.totalMap {
  margin-top: 20px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.header {
  background: #f7f8fc;
  display: flex;
  // margin-bottom: -10px;
  position: relative;
  z-index: 1;
  div {
    padding: 16px;
    border-right: 1px solid #dfdfdf;
  }
}
.table {
  // margin-top: -15px;
}

.col {
  div {
    padding: 16px;
  }
  border-bottom: 1px solid #f0f0f0;
  text-align: center;
  display: flex;
  justify-content: "space-between";
  background: #fff;
  &:hover {
    background: #fafafa;
  }
}

.pageTabs {
  display: flex;
  font-size: 20px;
  background-color: #fff;
  height: 63px;
  line-height: 63px;

  p {
    color: #999999;
    cursor: pointer;
    font-weight: bold;

    &:nth-of-type(1) {
      margin-right: 10px;
      margin-left: 20px;
    }

    &:nth-of-type(3) {
      margin-left: 10px;
    }
  }

  .itemTab {
  }

  .action {
    color: #347afc;
  }
}
