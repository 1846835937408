@import "src/components/Style/mixin";

.root {
  margin: 0 auto;
  width: 1100px;
  @include mobile {
    width: 100%;
  }
}

.container {
  width: 100%;
  background-color: #367bfc;
  background-image: url(./head.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 65%;
  position: relative;
  border-radius: 10px;
}

.tabs {
  padding: 0 40px;
  position: absolute;
  bottom: 0;
}

.tab {
  cursor: pointer;
  display: inline-block;
  background-color: #0060ca;
  width: 130px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  text-align: center;
  color: #cccccc;
  border-radius: 5px 5px 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.current {
  background-color: #fff;
  font-weight: bold;
  color: rgb(0, 153, 255);
}

.title {
  position: relative;
  padding-top: 20px;

  .build {
    position: absolute;
    left: 200px;
    top: 50%;
    transform: translateY(-50%);
  }
  h1 {
    font-weight: 700;
    font-size: 32px;
    color: #ffffff;
    margin-left: 25px;
  }
  h2 {
    font-size: 18px;
    padding: 20px 25px;
    background-color: #fff;
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
  }
}
.schoolDetail {
  background-color: #f7f8fc;
  padding: 25px;
}
.tabContent {
  padding: 0 0 40px 0;
  position: relative;
  width: 1150px;
  @include mobile{
    width: 100%;
    overflow: scroll;
  }
}

.detailRow {
  text-indent: 2em;
}

video:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
