.container{
    padding-top: 20px;
}

.headTitle{
    line-height: 40px;
    margin-bottom: 10px;
    h1{
        font-size: 16px;
        color: #0079FE;
        font-weight: 700;
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
        padding-left: 40px;
    }
}

.childTitle{
    margin-top: 30px;
    h1{
        font-size: 14px;
        color: #0079FE;
        font-weight: 700;
        font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
        padding-left: 40px;
    }
}

.blueline{
    height: 40px;
    width: 7px;
    background-color: rgba(0, 153, 255, 1);
    display: block;
    float: left;
}

.title{
    font-size: 14px;
    font-family: '微软雅黑';
    height: 40px;
    line-height: 40px;
    color: #666666;
}

.main{
    padding:0px 40px;
}

.main_jy{
    padding:10px 40px 0 40px;
}

.content{
    height: 40px;
    line-height: 40px;
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: #cccccc;
}

.largecontent{
    height: auto;
    text-overflow: ellipsis;
    line-height: 1.9em;
    min-height: 160px;
}

.supercontent{
    min-height: 537px;
    margin-top: 15px;
}
.smallcontent{
    height: 45px;
    font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
    font-weight: 700;
    font-size: 14px;
    color: #666666;
    a{
        color: #0099FF;
        margin-left: 5px;

    }
}

.childtitle{
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;
    color: #666666;
    font-size: 14px;
    margin-bottom: 15px;
}

.table{
    margin-top: 15px;
    padding:0px 40px;
    table{
        width: 100%;
    }
    th{
        height: 45px;
        background-color: #f5f5f5;
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
        font-weight: 700;
    }
    td{
        height: 40px;
        font-size: 12px;
    }
    tr,th,td{
        border: 1px solid #cccccc;
        color: #666666;
        padding-left: 15px;
    }
}

.tablewid{
    width: 100px;
}
