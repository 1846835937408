
.root{
  width: 100vw;
  height: 100vh;
  background: #000a3b;
  overflow: auto;
  color: #fff;
}

.container {
  background: url("./img/bg.png") center 32px no-repeat;
  width: 1920px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.header {
  position: relative;
  height: 116px;
  margin-bottom: 36px;
}

.buttons{
  position: absolute;
  right: 111px;
  top: 51px;
  white-space: nowrap;
}

.btn, .btn:active, .btn:focus{
  width: 140px;
  height: 50px;
  background: #052067;
  border: 2px #3a77a6 solid;
  color: #a3cade;
  font-size: 16px;
  outline: 0;
  transform: skewX(327deg);
  cursor: pointer;
  margin-left: 10px;

  span {
    transform: skewX(33deg);
    display: block;
  }
}

.btnActive {
  background: #0443bb;
  border-color: #20a8d5;
}

.main{
  display: flex;
  padding: 0 23px;
  justify-content: space-between;
}

.left {
  width: 505px;
  flex-grow: 0;
}

.right{
  flex-grow: 0;
  width: 510px;
}

.middle{
  flex-grow: 0;
  width: 810px;
}

.blockHeader{
  cursor: pointer;
  min-width: 220px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 35px;
  font-size: 18px;
  color: #02d9fd;
  border-top: 2px #006cc8 solid;
  border-bottom: 2px #006cc8 solid;
  text-align: center;
  background: #052666;
  white-space: nowrap;

  &:before, &:after{
    position: absolute;
    left: -11px;
    top: -2px;
    content: "";
    width: 12px;
    height: 39px;
    background: url("./img/title-bg.png") no-repeat;
  }

  &:after{
    left: auto;
    right: -11px;
    transform: scale(-1);
  }
}


@mixin boxBorder {
  content: "";
  position: absolute;
  width: 25px;
  height: 28px;
  background-repeat: no-repeat;
}

@mixin block {
  position: relative;
  margin-bottom: 40px;
  border: 2px #1a3f81 solid;
  box-shadow: 0 0 5px 5px #052058 inset;

  &:before{
    @include boxBorder;
    top: -3px;
    left: -2px;
    background-image: url("./img/border-top-left.png");
  }

  &:after{
    @include boxBorder;
    top: -3px;
    right: -5px;
    background-image: url("./img/border-top-right.png");
  }

  .blockInner{
    min-height: 200px;
    overflow: hidden;

    &:before{
      @include boxBorder;
      bottom: -6px;
      left: -2px;
      background-image: url("./img/border-bottom-left.png");
    }

    &:after{
      @include boxBorder;
      bottom: -3px;
      right: -3px;
      background-image: url("./img/border-bottom-right.png");
    }
  }

  &.noCorner{
    &:before, &:after{
      display: none;
    }

    .blockInner{
      &:before, &:after{
        display: none;
      }
    }
  }

  &.noBorder{
    border-color: transparent;
    box-shadow: none;
  }
}

.leftBlock1{
  @include block;
}

.leftBlock2{
  @include block;
}

.leftBlock3{
  @include block;
}

.middleBlock1{
  @include block;
  padding: 20px;
}

.middleBlock2{
  @include block;
}

.rightBlock1{
  @include block;
}

.rightBlock2{
  @include block;
}

.rightBlock3{
  @include block;
}
