.head{
    background: #fafafa;
}
.table td{
    padding: 5px 5px !important;
}
.table{
    :global(.ant-table-container){
        overflow: auto;
    }
}
