@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
  @include mobile {
    width: 100%;
    overflow: scroll;
  }
}

.search {
  padding: 20px 20px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.contentList {
  margin-top: 20px;
  padding: 20px 30px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.title {
  font-size: 24px;
  color: #666;
  font-weight: 409;
}

.tip {
  color: #999;
}

.table {
  width: 100%;

  th {
    height: 30px;
    padding: 0 16px !important;
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(233, 233, 233, 1);
    font-weight: bold !important;
    color: #999;
  }

  td {
    padding: 0 20px;
    font-size: 12px;
    height: 45px;
    border-bottom: 1px rgba(233, 233, 233, 1) solid;
    border-right: 1px rgba(233, 233, 233, 1) solid;
  }

  tr:nth-child(2n) {
    background: rgba(249, 249, 249, 1);
  }

  tr:nth-child(2n + 1) {
    background: #fff;
  }
}

.tablePop {
  width: 100%;

  th {
    height: 30px;
    padding: 0 16px !important;
    font-size: 14px;
    text-align: center;
    border: 1px solid rgba(233, 233, 233, 1);
    font-weight: bold !important;
    color: #999 !important;
  }

  td {
    padding: 0 20px;
    font-size: 12px;
    height: 45px;
    border-bottom: 1px rgba(233, 233, 233, 1) solid;
    border-right: 1px rgba(233, 233, 233, 1) solid;
  }

  tr:nth-child(2n) {
    background: rgba(249, 249, 249, 1);
  }

  tr:nth-child(2n + 1) {
    background: #fff;
  }
}

.mapTitle {
  font-size: 20px;
  color: #347afc;
  font-weight: bold;
  display: block;
  text-align: center;
}
