.root {
  padding-bottom: 65px;

  :global{
    .ant-table-thead > tr > th{
      border-left: 1px #eee solid;
      border-top: 1px #eee solid;
    }
  }
}

.content {
  width: 1300px;
  margin: 0 auto;
}

.contentHeader {
  width: 100%;
  height: 91px;
  line-height: 91px;
  padding: 0 20px;
  background: #0099ff;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.container {
  padding: 35px 60px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.customers
{
  width:100%;
  border-collapse:collapse;
  border: 1px solid #e9e9e9;
}

.customers td, .customers th
{
  max-width: 180px;
  font-size:12px;
  padding:6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color:#666666;
}

.customers th
{
  padding:6px 0 6px 8px;
  font-size:12px;
  font-weight: bold;
  text-align:left;
  background-color:#f5f5f5;
  color:#666666;
}

.customers tr.alt td
{

  background-color:#fbfbfb;
}

