@import "src/components/Style/mixin";

.root {
  position: absolute;
  left: 80px;
  top: 1300px;
}

.circle {
  transition: stroke-dasharray 1s;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 135px;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

.percent {
  font-size: 24px;
  line-height: 2;
}

.wrap {
  display: flex;
  // margin: 0px -20px;
  // margin-bottom: 30px;
  flex-wrap: wrap;
  @include mobile {
    display: block;
    margin: 0px 20px;
  }
}

.block1 {
  width: 31%;
  background-image: url(./1.png), linear-gradient(to right, #5f72fc, #bc7cfe);
  background-repeat: no-repeat;
  background-position: 90%;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  // width: 100%;
  margin: 0px 10px;
  margin-bottom: 25px;
  position: relative;
  @include mobile {
    margin: 0;
    margin-bottom: 20px;
  }
  .shadow {
    width: 90%;
    height: 25px;
    position: absolute;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    left: 5%;
    background-image: linear-gradient(to right, #5f72fc, #bc7cfe);
    filter: blur(5px);
    opacity: 0.5;
    bottom: -12px;
  }
}

.block2 {
  @extend .block1;
  background-image: url(./2.png), linear-gradient(to right, #3da5f6, #6f81fb);
  .shadow {
    background-image: linear-gradient(to right, #3da5f6, #6f81fb);
  }
}

.block3 {
  @extend .block1;
  background-image: url(./3.png), linear-gradient(to right, #ea6373, #ef9863);
  .shadow {
    background-image: linear-gradient(to right, #ea6373, #ef9863);
  }
}
.block4 {
  @extend .block1;
  background-image: url(./4.png), linear-gradient(to right, #4ed992, #94df57);
  .shadow {
    background-image: linear-gradient(to right, #4ed992, #94df57);
  }
}

.block5 {
  @extend .block1;
  background-image: url(./5.png), linear-gradient(to right, #27d5f9, #1d9be9);
  .shadow {
    background-image: linear-gradient(to right, #27d5f9, #1d9be9);
  }
}

.block6 {
  @extend .block1;
  background-image: url(./6.png), linear-gradient(to right, #5f72fc, #bc7cfe);
}

.block7 {
  @extend .block1;
  background-image: url(./7.png), linear-gradient(to right, #3da5f6, #6f81fb);
  .shadow {
    background-image: linear-gradient(to right, #3da5f6, #6f81fb);
  }
}

.block8 {
  @extend .block1;
  background-image: url(./8.png), linear-gradient(to right, #ea6373, #ef9863);
  .shadow {
    background-image: linear-gradient(to right, #ea6373, #ef9863);
  }
}

.block9 {
  @extend .block1;
  background-image: url(./9.png), linear-gradient(to right, #4ed992, #94df57);
  .shadow {
    background-image: linear-gradient(to right, #4ed992, #94df57);
  }
}

.label {
  font-size: 20px;
}
.number {
  font-size: 34px;
  // span{
  //   font-size: 20px;
  // }
}
.txtNum {
  font-size: 45px;
  font-weight: bold;
}


.newLine{
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding: 0 10px;
  width: 100%;
  margin-bottom: 20px;
}

.newItem{
  position: relative;
  // flex: 1;
  background-color: red;
  color: #fff;
  width: 30%;
  margin: 0 auto;
  &1{
    background: url("./newItem1-bg.png") center center/contain no-repeat;

    .newItemLabel{
      background: url("newItem1-dot.png") left 6px no-repeat;
    }
  }

  &2{
    background: url("./newItem2-bg.png") center center/contain no-repeat;

    .newItemLabel{
      background: url("newItem2-dot.png") left 6px no-repeat;
    }
  }

  &3{
    background: url("./newItem3-bg.png") center center/contain no-repeat;

    .newItemLabel{
      background: url("newItem3-dot.png") left 6px no-repeat;
    }
  }

  &:after{
    content: "";
    display: block;
    height: 0;
    padding-bottom: 76.70250896057348%;
  }

  span{
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
}

.newItemStatic{
  margin-bottom: 20px;
}

.newInner{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  padding-left: 25px;
  padding-top: 40px;
  // display: flex;
}

.newItemLabel{
  padding-left: 30px;
  font-size: 16px;
}

.newItemCount{
  padding-left: 30px;
  font-size: 26px;
}
