@import "src/components/Style/mixin";

.root {
  padding-bottom: 65px;
}

.content {
  width: 1300px;
  margin: 0 auto;
  @include mobile {
    width: 100%;
  }
}

.title {
  font-size: 24px;
  color: #666666;
}

.search {
  padding: 20px 20px;
  width: 100%;
  height: 72px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  @include mobile {
    height: auto;
  }
}

.blueButton {
  border: 1px solid #1890ff;
  color: #1890ff;
}

.list {
  margin-top: 20px;
  padding: 20px 20px 60px 20px;
  background: #ffffff;
  border: 1px solid #e9e9e9;

}

.listTitle {
  font-size: 28px;
  color: #666;
}

.listIntroduction {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}

.schoolTable {
  margin-top: 20px;
  @include mobile{
    overflow: auto;
  }
}

.customers {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #e9e9e9;
  @include mobile{
    overflow: scroll;
  }
}

.customers td,
.customers th {
  max-width: 180px;
  font-size: 12px;
  padding: 6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color: #666666;
  text-align: center;
}

.customers th {
  padding: 6px 0 6px 8px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
  color: #666666;
}

.customers tr.alt td {
  background-color: #fbfbfb;
}

.antButton {
  padding: 0 5px;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6c97d2;
  height: auto;
  fontsize: 12;
}

.antButton > span {
  display: inline;
  color: #6c97d2;
}

.tableButton {
  padding: 0;
  height: 24px;
  line-height: 22px;
  width: 90px;
  border-radius: 24px;
  border: 1px solid #1890ff;
  font-size: 12px;
  color: #1890ff;
}
