.table{
    // border:1px solid red;
    width:100%;
    // flex:1;
}
// .table2{
//     width:900px;
//     border:1px solid red;
// }
.table3{
    display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 120px;
}
.popoverContent{
    .title{
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        font-size: 14px;
        // color: #999;
        height: 30px;
        margin-bottom: 10px;

    }
    .more{
        color: #116dfa;
        cursor: pointer;
    }
}
.popoverColumns{
    cursor: pointer;
    &:hover{
        color: #116dfa;
    }
}

:global{

  .info-card-text:before{
    position: absolute;
    content: "";
    width: 35px;
    height: 35px;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  //.safe_5_A .info-card-text:before{
  //  background: url("../Block1/img/icon3.png") -86px 0 no-repeat;
  //}
  //
  //.safe_5_B .info-card-text:before{
  //  background: url("../Block1/img/icon3.png") -86px -66px no-repeat;
  //}
  //
  //.safe_5_C .info-card-text:before{
  //  background: url("../Block1/img/icon3.png") -86px -131px no-repeat;
  //}
  //
  //.safe_5_D .info-card-text:before{
  //  background: url("../Block1/img/icon3.png") -86px -202px no-repeat;
  //}
  //
  //.safe_5_E .info-card-text:before{
  //  background: url("../Block1/img/icon3.png") -86px -271px no-repeat;
  //}




  //.page_3_A .info-card-text:before{
  //  background: url("../Block1/img/icon1.png") -65px 0 no-repeat;
  //}
  //
  //.page_3_B .info-card-text:before{
  //  background: url("../Block1/img/icon1.png") -65px -60px no-repeat;
  //}
  //
  //.page_3_C .info-card-text:before{
  //  background: url("../Block1/img/icon1.png") -65px -120px no-repeat;
  //}





  //.wisdom_ln_A .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px 0 no-repeat;
  //}
  //
  //.wisdom_ln_B .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px -48px no-repeat;
  //}
  //
  //.wisdom_ln_C .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px -96px no-repeat;
  //}
  //
  //.wisdom_ln_D .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px -151px no-repeat;
  //}
  //
  //.wisdom_ln_E .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px -202px no-repeat;
  //}
  //
  //.wisdom_ln_F .info-card-text:before{
  //  background: url("../Block1/img/icon2.png") -86px -254px no-repeat;
  //}
}

