
.root{
  cursor: pointer;
  background: url("./img/active-item.png") top left no-repeat;
  background-size: 100% 100%;
}

.text{
  position: relative;
  //display: none;
  height: 100%;
  padding: 30px 34px 25px 43px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #333;
  //
  //&::before{
  //  content: '';
  //  position: absolute;
  //  left: 17px;
  //  top: 38px;
  //  width: 17px;
  //  height: 18px;
  //  background: url("./img/circle.png") left center no-repeat;
  //  background-size: 100% 100%;
  //  vertical-align: middle;
  //}
}

.info{
  //display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 22px 15px 10px 19px;
  display: none;
  color: #000;
  //background: url("./img/bg_hover.png") top left no-repeat;
  background-size: 100% 100%;
}

.title{
  font-size: 14px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: normal;
  color: #333;
}

.footer{
  white-space: nowrap;
  color: #fff;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  background: #4393f4;
  bottom: 3px;
  left: 4px;
  width: calc(100% - 8px);
  border-radius: 0 0 4px 4px;
  padding: 0 15px;
  box-sizing: border-box;
}

.root:hover{

  .text{
    display: none;
  }

  .info{
    display: flex;
  }
}


@mixin info{
  display: inline-block;
  padding-left: 18px;
  font-size: 12px;
  padding-right: 10px;
  line-height: 24px;
  box-sizing: border-box;
  &:last-child{
    padding-right: 0;
  }
}

.info1{
  @include info;
  background: url("./img/card-icon1.png") no-repeat;
  background-size: 11px 10px;
  background-position: 0 7px;
}

.info2{
  @include info;
  background: url("./img/card-icon2.png") 2px 7.7px no-repeat;
  background-size: 11px 10px;
}

.info3{
  @include info;
  background: url("./img/card-icon3.png") 2px center no-repeat;
  background-size: 11px 10px;
}

.info4{
  @include info;
  background: url("./img/card-icon4.png") 2px center no-repeat;
  background-size: 11px 10px;
}

.more{
  @include info;
  background: url("./img/right.png") 2px center no-repeat;
  background-position: right center;
  padding-right: 10px !important;
  background-size: 8px 10px;
  position: absolute;
  right: 10px;
  top: 3px;
  padding-left: 0 !important;
}
