.container {
  padding: 36px 66px;
  background: #ffffff;
}

.basicContainer{
  padding: 0px 66px 36px 0;
}

.customers
{
  width:100%;
  border-collapse:collapse;
  border: 1px solid #e9e9e9;
}

.customers td
{
  font-size:12px;
  padding:6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color:#666666;
}

.customers tr.alt td
{

  background-color:#fbfbfb;
}

.ulLi {
  line-height: 20px;
}

.tableLabel {
  display: inline-block;
  vertical-align: middle;
  width: 156px;
  color: #000000;
  font-weight: bold;
}

.tableContent {
  max-width: 780px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableTwoContent {
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tableSubContent {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  max-width: 780px;
  max-height: 60px;
  overflow: hidden;
  transition: height .5s;
}

.tableTotalContent {
  display: inline-block;
  vertical-align: middle;
  max-width: 780px;
  line-height: 25px;
}

//展开收起
.tableClick {
  max-height: 5000px;
}


.admission
{
  width:500px;
  border-collapse:collapse;
  border: 1px solid #e9e9e9;
}

.admission td, .admission th
{
  font-size:12px;
  padding:6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color:#666666;
}

.admission th
{
  padding:6px 0 6px 8px;
  font-size:12px;
  font-weight: bold;
  text-align:left;
  background-color:#f5f5f5;
  color:#666666;
}

.admission tr.alt td
{
  background-color:#fbfbfb;
}
