
.root{
  min-height: 2000px;
  padding-bottom: 50px;
  background: rgb(240,242,245);
}

.content{
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
