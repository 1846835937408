.titleCount{
    width: calc(100% - 8px);
    height: 64px;
    margin: 0 auto;
    background: linear-gradient(180deg, #EAF4FF 0%, #fff 100%);
    //box-shadow: 0px 2px 6px 0px rgba(183, 199, 252, 0.5);
    //border-radius: 8px;
    //border: 1px solid #828CBD;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .titleCountEtem{
        // background-color: blue;
        width:33%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 20px;
        }
        p{
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #031C9B;
            padding:0;
            margin:0 5px;;
        }
        span{
            font-size: 24px;
            font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
            font-weight: 800;
            color: #4393f4;
        }
    }
    .rule{
        display: none;
        width: 1px;
        height: 45px;
        border: 1px solid;
        border-image: linear-gradient(180deg, rgba(0, 22, 135, 1), rgba(174, 191, 241, 0)) 1 1;
    }
}
