@import "mixin";

.container{
  width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @include mobile{
    width: auto;
  }
}
