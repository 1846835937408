
.root{
  height: 245px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    border: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    border-radius: 4px;
    // background: #D6DBE5;
  }
}
