@import "src/components/Style/mixin";
.root {
  height: 100vh;
  padding: 0 50px;
  overflow: hidden;
  background: url("./img/bg.jpg") center top no-repeat;
  user-select: none;
  @include mobile {
    padding: 0;
    overflow: auto;
    height: auto;
  }
}

.container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-top: 80px;
  font-size: 48px;
  color: #fff;
  font-family: "Microsoft YaHei", serif;
  @include mobile {
    display: none;
  }
  svg {
    @include mobile {
      width: 26px;
    }
  }
}

.main {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    display: grid;
  }
}
