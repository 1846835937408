
.wrapper{
  padding: 20px 100px;
}

.title{
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}
