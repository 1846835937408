@import "src/components/Style/mixin";

.dragTransfer {
  border: 1px solid #dfdfdf;
  width: 200px;
  //margin: 0px 10px;
  height: 200px;
  margin-bottom: 15px;
}
.dragTransferFields {
  @extend .dragTransfer;
  height: 415px;
  .transferContent {
    height: 375px;
    overflow: auto;
  }
}
.dragTransferItem {
  cursor: pointer;
  width: 100%;
  padding: 5px;
  transition: 0.2s ease-in-out;
  user-select: none;
  &:hover {
    background: #fafafa;
  }
  :global {
    .ant-radio-wrapper {
      margin: 0;
      width: 100%;
    }
  }
}
.modal {
  max-width: 1062px;
  @include mobile {
    width: 100% !important;
  }
}
.title {
  border-bottom: 1px solid #dfdfdf;
  padding: 8px;
  height: 40px;
}
.transferContent {
  width: 100%;
  height: 160px;
  overflow: auto;
}
.filterList {
  overflow: auto;
  height: 415px;
  width: 385px;
}
