.infoTitle{
    line-height: 40px;
    margin-bottom: 25px;
    a{
        color: #666666;
        text-decoration: underline;
    }
    h1{
        font-size: 14px;
        color: #666666;
        font-weight: 700;
        font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑', serif;
    }
    span::before{
        content: ">";
        margin: 0 7px;
    }
}
.blueline{
    height: 40px;
    width: 7px;
    background-color: rgba(0, 153, 255, 1);
    display: block;
    position: absolute;
    left: 0px;
}

.contentHeader {
  width: 100%;
  height: 91px;
  line-height: 91px;
  padding: 0 20px;
  background: #0099ff;
  font-size: 32px;
  font-weight: bold;
  color: #ffffff;
}

.container {
  padding: 35px 60px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
}

.customers
{
  width:100%;
  border-collapse:collapse;
  border: 1px solid #e9e9e9;
}

.customers td, .customers th
{
  max-width: 180px;
  font-size:12px;
  padding:6px 0 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e9e9e9;
  color:#666666;
}

.customers th
{
  padding:6px 0 6px 8px;
  font-size:12px;
  font-weight: bold;
  text-align:left;
  background-color:#f5f5f5;
  color:#666666;
}

.customers tr.alt td
{

  background-color:#fbfbfb;
}
