
.btn{
  color: #333;
  display: flex;
  align-items: center;
}

.avatar{
  margin: 0 10px;
  width: 22px;
  height: 22px;
}
