
.root{

}

.title{
  padding-top: 300px;
  font-size: 30px;
  text-align: center;
  color: #fff;
}
