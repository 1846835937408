.table{
  margin: 0 auto;
  color: #ffffff;
  border-collapse: collapse;
  border-radius: 10px;

  tr, th, td{
    padding: 20px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 26px;
  }

  &Wrapper{
    border: 3px solid #2ec7c9;
    padding: 20px 30px 30px 30px;
    height: 600px;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 0 22px 1px #2ec7c9 inset;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      background   : #535353;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background   : #ededed;
    }
  }

  &Title{
    margin-bottom: 40px;
    color: #ffffff;
    font-size: 40px;
  }
}

.tableService{
  tr, th, td{
    padding: 20px 5px;
    border: 1px solid #2ec7c9;
    text-align: center;
    font-size: 20px;
  }
}
