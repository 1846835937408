
.root{
  flex: 1;
  margin: 10px;
  padding-top: 25px;
  overflow: hidden;

  :global{
    .ant-tabs{
      color: #dcdcdc;
      //border: 2px #8a9cc5 solid;
      border-radius: 24px;
      background: #fff;
    }

    .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after{
      display: none !important;
    }
    .ant-tabs-top > .ant-tabs-nav{
      margin-bottom: 25px;
    }
    .ant-tabs, .ant-tabs-nav, .ant-tabs-nav-wrap, .ant-tabs-nav-list{
      overflow: visible !important;
    }

    .ant-tabs-nav-wrap{
      flex: 1 !important;
      border-radius: 24px 24px 0 0;
      border-bottom: 1px solid #eee;
      //background: linear-gradient(#c8e2fd, #fff);
    }

    .ant-tabs-nav-list{
      flex: 1;
      margin-top: -20px;
      margin-left: -2px;
      margin-right: -2px;
      display: flex;
      align-items: stretch;
      height: 80px;
      transform: none !important;
    }

    .ant-tabs-nav-operations{
      display: none !important;
    }

    .ant-tabs-nav-list{
      //padding-left: 30px;
    }

    .ant-tabs-ink-bar{
      display: none;
    }

    .ant-tabs-tab{
      margin-right: 0;
      padding: 20px 0px 0;
      line-height: 1;
      font-size: 26px;
      justify-content: center;

      &:first-child{
        border-left: 0;
      }

      flex: 0 0 25%;
    }

    .ant-tabs-tab-active{
      transform: translateY(10px);
      padding-top: 0;
      &:nth-child(1){
        background: url("./img/activetab.png") top left /100% 100% no-repeat;
      }

      &:nth-child(2){
        background: url("./img/activetab.png") top left /100% 100% no-repeat;
      }

      &:nth-child(3){
        background: url("./img/activetab.png") top left /100% 100% no-repeat;
      }

      &:nth-child(4){
        background: url("./img/activetab.png") top left /100% 100% no-repeat;
      }
    }

    .ant-tabs-tab-btn{
      font-size: 22px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333;
      line-height: 33px;
      transition: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      //font-weight: bold;
      color: #fff;
    }

    .ant-tabs-top > .ant-tabs-nav::before{
      display: none;
    }
  }
}

.tabs{
  display: flex;
  align-items: center;
  width: 90%;
  height: 40px;
  background: #546fa9;
  border-radius: 40px;
  margin: 0 auto;
  margin-bottom: 20px;
  div{
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    height: 100%;
    line-height: 40px;
  }
}

.tabsactive{
  background: #4393f4;
  height: 100%;
  border-radius: 40px;
}
