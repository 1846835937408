
.root{
  position: relative;
}

.more{
  //position: absolute;
  //right: 0;
  //top: 1px;
  padding: 0;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  border: none;
  background: transparent;
  img{
    display: block;
  }
}
