.header{
  background: #f7f8fc;
  display: flex;
  // margin-bottom: -10px;
  position: relative;
  z-index: 1;
}
.table{
  // margin-top: -15px;
}

.col{
  // border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  // padding: 16px;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  div{
    padding: 16px;
    border-right: 1px solid #f0f0f0;
  }
  &:hover{
    background: #fafafa;
  }
}

.header{
  div{
    border-right: 1px solid #dfdfdf;
    padding: 16px;
  }
}

.img{
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}

.titleheader{
  text-align: center;
  color: #347afc;
  font-size: 20px;
  font-weight: bold;
  background-color: #fff;
  padding: 20px;
}
