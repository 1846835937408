@import "src/components/Style/mixin";

.root {
  position: relative;
}

.line {
  margin-bottom: 40px;
  width: 100%;
  height: 66px;
  background: rgba(85, 85, 242, 0.09);
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  @include mobile {
    margin-bottom: 120px;
  }
}

.wrapper {
  margin: 0 auto;
  width: 687px;
  height: 92px;
  background: url(./images/big_title_bg.png) top center no-repeat;
  @include mobile {
    width: 100%;
    height: 0;
  }
}

.title {
  position: relative;
  padding-top: 5px;
  line-height: 67px;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 5px;
  color: #fff;
  text-shadow: 0 4px 16px rgba(0, 59, 107, 0.44);
  text-align: center;

  &:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: #6cb5ff;
    z-index: 2;
    -webkit-mask-image: -webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 59, 107, 0)), to(#fff));
    @include mobile {
      -webkit-mask-image: -webkit-gradient(linear, 0 0, 0 bottom, from(rgba(0, 59, 107, 1)), to(#fff));
    }
  }
  @include mobile {
    bottom: -68px;
    line-height: 50px;
  }
}

.subTitle {
  width: 687px;
  margin: -5px auto 0 auto;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  color: rgba(156, 219, 255, 0.6);
  letter-spacing: normal;
}

.time {
  position: absolute;
  left: 50px;
  font-size: 14px;
  line-height: 66px;
  vertical-align: top;
  color: #b3c1ff;
}
