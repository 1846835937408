@import "src/components/Style/mixin";

.root {
  //position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #02002e;
  overflow-y: hidden;
  position: absolute;
  @include mobile {
    width: 100%;
    overflow-y: auto;
    position: unset;
  }
}

.list {
  width: 80px;
  height: 50px;
  background: rgba(85, 85, 242, 0.2);
  color: #24ffff;
  text-align: center;
  margin-left: 50px;
  margin-bottom: 20px;
  padding-top: 5px;

  .listTop {
    color: #fff;
  }
}
.label {
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  right: 20px;
  z-index: 5;
  .len {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #ee167b;
    margin-right: 5px;
  }
  .len2 {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #dab606;
    margin-right: 5px;
  }
}

.label2 {
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  right: 20px;
  z-index: 5;
  .len {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #38ba76;
    margin-right: 5px;
  }
  .len2 {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #4467f4;
    margin-right: 5px;
  }
}
.button1 {
  margin: 0 auto;
  position: relative;
  height: 50px;
  left: 170px;
  @include mobile {
    left: auto;
  }
  display: inline-block;
  cursor: pointer;
  .l {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAEp0lEQVR4Xu3dW2/cRBgG4NfNkkNRD6RpC4SeCylkvTmsSg9py/aASiK1BNGNCtxzxwU3WXu3SP4N/AwESPwRrnuPhJCoEEKIPXpeNGYXGprZOGvvrj2elVaRHEdyHn3zeebzzNhC91P2OPm0hUfCR653zPwErOcRCi6v+AILBuY/gR1AxQqPNIAPDZACSB62Hd4TxOsG6R+BHRHUBToviBsGSAFU/JwvteZQNslaASQPF1ze9AXOmSjapYkFQNt8w7dwxwApgDyPB75toExiKutILyTpHkhhm1d9C28ZIIXAUo3znQ7uGiCFQPkbTjz9EY8FMZFlJGUTC5J1lXd9H/MGSCFgu7wkBN41QAqBtW0e+t3CRwaoj4DtclMIHM4qUt8cJFEWHV4G8bYBUggUKzzdAEoGSCFQ8jj9ax1bBsjkoV0F9sxB8q/yFV4ncDGLURQKaNXhxSZx3QApBK54PPxnHZsGqI9Avsot+pjOGlKoJhb0h6q8DR+nDJBCIF9lgT6WDZBCIKtl2NBNrOjxYKOORyaCTKLeIRA6goIO4zbfp4XXshRF+wKyHRYFsWiAVIn6Cc/5bdw0QOrSR+Zmf+yriZG0bAefETiQlSjaF5BEKbh86AscNUCqHrXDEonTBkghsORwpUPYBkgVQU94gW2sGSDVnczjXKOODQOkEHjH46RVx2MD1G9MVuEWkY3i2b5v80HxrML7AE5mIYoGArJdXhMCbxog1a3+Ky52WigaINWtvsZT7OC2AVIIZOkx0EA5KEuD1oGAsjRoHRjIrvCWAM7qnocGBlqscAmA/Gr9GRhopcYzrQ7e01pnt+VQYf/hZY9H23U8DHt+Ws8bOIKC9Rx1fKp7+XVgIBkRtssNITCX1ugIc92RgLKwCDgSUBZmnkUCsh2+IogHYUI1redEApKJ+rsGPtF5RVAkoCBRV7gugONpjZC9rjsykO5LFSID6b5UITKQ7ksVIgPpXvqIC0jbXWNiAdJ5ZB8LkM55KBYgnfNQbEC6TouJDWjF5fGWwPpePdO0/T42oOBRUA1l3VYExQYUTGpwuQaBC2mLkn7XGyuQjsOOeIHk9l6z2NKp/BErULdOrdXUmPiBNKsyxg4UdBodfuATJ3RI1kMBWnZ4tk3cMkAKgW6t+mNBzKQdaSgRFDSzKvO+j1UD1C+K6ngggCNpRhpaBEmUfJUn6UNOGU7tZ6hAAVLKN0YZOtD615z66WdspnVXz6EDBb1rh6ndgnkkQMFIP6V7oY0MSNaL8jWU0rZBysiAZBSVPOae1XGfwLG03NZGCiRRrn3JmT+msAHi5TQgjRxIosgJoKKJe77AwaQjjQVIosjdZJpN3KHAbJKRxgb0b05q4EaSl5mPFUgiybtbwcVqUjdNGTtQr3nJziSBy0nrcScGqNvkpp+1scpOcja1TBRQL5oKHk+giatJ2CMkkUASSlYlf2jiUlsgP84l6IkF6kWThPq+gzPoYGEcDwISD/R8H0lOXLcEFmjhvMBoXhSXKqAelhzT/fYXXsUE5uWrvkgcGlZnM5VA/8dY/4JTv8zgmJ/DbIAlx3nya2HSAnLCQo7ixVeFhUH9G3UMqxCVXeJ2AAAAAElFTkSuQmCC)
      0 0 no-repeat;
    background-size: 36px 50px;
  }

  .m {
    display: block;
    line-height: 50px;
    margin: 0 36px 0 36px;
    background: rgba(44, 119, 255, 0.37);
    color: #3ff;
    font-size: 12px;
    font-weight: 600;
  }

  .r {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAE30lEQVR4Xu3dzVMcRRgG8OfpHsJuqvRiebCseLA86cnyL7GsePTqzZvldQ9hZxat8uDNuxUBiVJRYxJNMB9FAlk+ZocPoWIiBgmpAAYhIUFmrK2SCmymoWd3A+zMy3GnZ4v+1dvdb/d0z/KtT6IPIH87BDRxz/f4U/VDCtCz0ZED+sslzgpQTMuhwmqliG9JRgIUA6Q1hv0ig61L0sRqkPQmev3PuCZA8QPTwniJZ7dfkgjaptEWYmD0U84IUFznDIRhHt0TBT4RoDggYjbw2F97SZrY/yJHHPw60sE/BCg+94nCdnTVNi/Jg55iPTN6yTC/LZIcoDxW4njcyC99EIAc0Fcu8YEAGeZegctTpgWNzEeQVvjNd3ldgAwCdHAx6OCfAmQQeO1VdJ35iI8FKEZAAQ8qJfaZcDKfB+kI034nrwmQQUC34bJ/grcEyARUszgmedCOdQysjXvs3S16Mt0HaYVbvsvLAmTKf9owFJzgpACZgDTOBkUuCJBBIPc6TpY/5IYAxSWICisVl9/thZPZTloBtyslXhIgU/5T8/RUEsUagZzG+XKR8xJBdc7gd+STWdv+oohHFY89NtGTyU6axN3A4zkBMnXQeyyx1t6WuTVppTBYcTklEWTKoBOMYJnsg3J5fFMu8KFEUIyAJjZ8jydtcTIXQQQWgxJ/ECCDQJvCzVGXVwXIvAY0GhTpC5ApgohLox5vC5BBQBGnKx6XBcgg8OY7+KrnfW4KUIxA9YjBbttcTGiZmWoo4q+Kx5+TRE+28iBictzjkAAZBByFa2MupwXInANZPQfL7HLHi0/QM/A5H0kExY1gwHpQYndSnMx00lpjzi/yFwEyTTE0/NEiRwXIILD9kG5SpEwkilEepyYKXE2Kk4k+iMTjwGNXPTjZAIowH3TyvAAZBLRG4Bc5LEAmoAgX/E7eESCDQJTH13EnCW3BUj2KUWEpcPm9LUZcuVQDoc4lju1QqQZqJEHcQko10Mt5dPcXuC5NLEbA5qiTDVxqI4gOJoIO3rBB2K1MaoGO5nFuqMC7AhQ7NmPjeA5dhQJDAYrvf6w3iu8FmMompogrFY+/71V5m+upA6JCdOwVdO92ktkGJs15kPFFJUlgUgtkc1A3CVSqmlh1F/17OfQ2Y/RKZQS1NfD0whRVqYmgaufc3o7eJFt8bZpaaoCSHJKzgUldE8vl8WO5wPtJKm9TNhURpBRmKi4HbCqctEzLA1Fj/dg/6DvzhfkVN0lRUrWi2MxpRerWpKkxHxTrfyhoE1kt28QUsXk0h9PXC1yxqWi9ZVoWqBkL8jZoLQl0ROHGiMsJmwo2WqblgBwHU2MdHGy04rb3txSQJu6Mubi49UJ+20o2Uq6VgBZyy7hQ/nLvN7Y0AlJ7b2sAKdw83o6BZi5j2CIeeqDan3GwrVizyh1aoGqe42hciXtLeLMqb/M9hxJIK/ydA64Ouly0qcTzLHOogJTGv0pj7F2NyYPobw71XIzErHoJQ/7HT38V5XlGhu13H3gEaWAZEUYa2UdoW9l6yh0IEIGQwCzzmPILvFfPP75f9+wrkI7wMHQw/cIjzNRzNGm/UPZtwaz6pAEhlhyNOUaYGy7i/n5OE5oB2lAEVZtKVB15QmyGEdYdjVUQa2GIFQdYfONtLCU9ht2MSjXzO/4DZfovgzJGaioAAAAASUVORK5CYII=)
      0 0 no-repeat;
    background-size: 36px 50px;
  }
}

.button {
  margin: 0 auto;
  position: relative;
  height: 50px;
  left: 130px;
  display: inline-block;
  cursor: pointer;
  @include mobile {
    left: auto;
  }
  .l {
    position: absolute;
    left: 0;
    top: 0;
    width: 36px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAEp0lEQVR4Xu3dW2/cRBgG4NfNkkNRD6RpC4SeCylkvTmsSg9py/aASiK1BNGNCtxzxwU3WXu3SP4N/AwESPwRrnuPhJCoEEKIPXpeNGYXGprZOGvvrj2elVaRHEdyHn3zeebzzNhC91P2OPm0hUfCR653zPwErOcRCi6v+AILBuY/gR1AxQqPNIAPDZACSB62Hd4TxOsG6R+BHRHUBToviBsGSAFU/JwvteZQNslaASQPF1ze9AXOmSjapYkFQNt8w7dwxwApgDyPB75toExiKutILyTpHkhhm1d9C28ZIIXAUo3znQ7uGiCFQPkbTjz9EY8FMZFlJGUTC5J1lXd9H/MGSCFgu7wkBN41QAqBtW0e+t3CRwaoj4DtclMIHM4qUt8cJFEWHV4G8bYBUggUKzzdAEoGSCFQ8jj9ax1bBsjkoV0F9sxB8q/yFV4ncDGLURQKaNXhxSZx3QApBK54PPxnHZsGqI9Avsot+pjOGlKoJhb0h6q8DR+nDJBCIF9lgT6WDZBCIKtl2NBNrOjxYKOORyaCTKLeIRA6goIO4zbfp4XXshRF+wKyHRYFsWiAVIn6Cc/5bdw0QOrSR+Zmf+yriZG0bAefETiQlSjaF5BEKbh86AscNUCqHrXDEonTBkghsORwpUPYBkgVQU94gW2sGSDVnczjXKOODQOkEHjH46RVx2MD1G9MVuEWkY3i2b5v80HxrML7AE5mIYoGArJdXhMCbxog1a3+Ky52WigaINWtvsZT7OC2AVIIZOkx0EA5KEuD1oGAsjRoHRjIrvCWAM7qnocGBlqscAmA/Gr9GRhopcYzrQ7e01pnt+VQYf/hZY9H23U8DHt+Ws8bOIKC9Rx1fKp7+XVgIBkRtssNITCX1ugIc92RgLKwCDgSUBZmnkUCsh2+IogHYUI1redEApKJ+rsGPtF5RVAkoCBRV7gugONpjZC9rjsykO5LFSID6b5UITKQ7ksVIgPpXvqIC0jbXWNiAdJ5ZB8LkM55KBYgnfNQbEC6TouJDWjF5fGWwPpePdO0/T42oOBRUA1l3VYExQYUTGpwuQaBC2mLkn7XGyuQjsOOeIHk9l6z2NKp/BErULdOrdXUmPiBNKsyxg4UdBodfuATJ3RI1kMBWnZ4tk3cMkAKgW6t+mNBzKQdaSgRFDSzKvO+j1UD1C+K6ngggCNpRhpaBEmUfJUn6UNOGU7tZ6hAAVLKN0YZOtD615z66WdspnVXz6EDBb1rh6ndgnkkQMFIP6V7oY0MSNaL8jWU0rZBysiAZBSVPOae1XGfwLG03NZGCiRRrn3JmT+msAHi5TQgjRxIosgJoKKJe77AwaQjjQVIosjdZJpN3KHAbJKRxgb0b05q4EaSl5mPFUgiybtbwcVqUjdNGTtQr3nJziSBy0nrcScGqNvkpp+1scpOcja1TBRQL5oKHk+giatJ2CMkkUASSlYlf2jiUlsgP84l6IkF6kWThPq+gzPoYGEcDwISD/R8H0lOXLcEFmjhvMBoXhSXKqAelhzT/fYXXsUE5uWrvkgcGlZnM5VA/8dY/4JTv8zgmJ/DbIAlx3nya2HSAnLCQo7ixVeFhUH9G3UMqxCVXeJ2AAAAAElFTkSuQmCC)
      0 0 no-repeat;
    background-size: 36px 50px;
  }

  .m {
    display: block;
    line-height: 50px;
    margin: 0 36px 0 36px;
    background: rgba(44, 119, 255, 0.37);
    color: #3ff;
    font-size: 12px;
    font-weight: 600;
  }

  .r {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 50px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAE30lEQVR4Xu3dzVMcRRgG8OfpHsJuqvRiebCseLA86cnyL7GsePTqzZvldQ9hZxat8uDNuxUBiVJRYxJNMB9FAlk+ZocPoWIiBgmpAAYhIUFmrK2SCmymoWd3A+zMy3GnZ4v+1dvdb/d0z/KtT6IPIH87BDRxz/f4U/VDCtCz0ZED+sslzgpQTMuhwmqliG9JRgIUA6Q1hv0ig61L0sRqkPQmev3PuCZA8QPTwniJZ7dfkgjaptEWYmD0U84IUFznDIRhHt0TBT4RoDggYjbw2F97SZrY/yJHHPw60sE/BCg+94nCdnTVNi/Jg55iPTN6yTC/LZIcoDxW4njcyC99EIAc0Fcu8YEAGeZegctTpgWNzEeQVvjNd3ldgAwCdHAx6OCfAmQQeO1VdJ35iI8FKEZAAQ8qJfaZcDKfB+kI034nrwmQQUC34bJ/grcEyARUszgmedCOdQysjXvs3S16Mt0HaYVbvsvLAmTKf9owFJzgpACZgDTOBkUuCJBBIPc6TpY/5IYAxSWICisVl9/thZPZTloBtyslXhIgU/5T8/RUEsUagZzG+XKR8xJBdc7gd+STWdv+oohHFY89NtGTyU6axN3A4zkBMnXQeyyx1t6WuTVppTBYcTklEWTKoBOMYJnsg3J5fFMu8KFEUIyAJjZ8jydtcTIXQQQWgxJ/ECCDQJvCzVGXVwXIvAY0GhTpC5ApgohLox5vC5BBQBGnKx6XBcgg8OY7+KrnfW4KUIxA9YjBbttcTGiZmWoo4q+Kx5+TRE+28iBictzjkAAZBByFa2MupwXInANZPQfL7HLHi0/QM/A5H0kExY1gwHpQYndSnMx00lpjzi/yFwEyTTE0/NEiRwXIILD9kG5SpEwkilEepyYKXE2Kk4k+iMTjwGNXPTjZAIowH3TyvAAZBLRG4Bc5LEAmoAgX/E7eESCDQJTH13EnCW3BUj2KUWEpcPm9LUZcuVQDoc4lju1QqQZqJEHcQko10Mt5dPcXuC5NLEbA5qiTDVxqI4gOJoIO3rBB2K1MaoGO5nFuqMC7AhQ7NmPjeA5dhQJDAYrvf6w3iu8FmMompogrFY+/71V5m+upA6JCdOwVdO92ktkGJs15kPFFJUlgUgtkc1A3CVSqmlh1F/17OfQ2Y/RKZQS1NfD0whRVqYmgaufc3o7eJFt8bZpaaoCSHJKzgUldE8vl8WO5wPtJKm9TNhURpBRmKi4HbCqctEzLA1Fj/dg/6DvzhfkVN0lRUrWi2MxpRerWpKkxHxTrfyhoE1kt28QUsXk0h9PXC1yxqWi9ZVoWqBkL8jZoLQl0ROHGiMsJmwo2WqblgBwHU2MdHGy04rb3txSQJu6Mubi49UJ+20o2Uq6VgBZyy7hQ/nLvN7Y0AlJ7b2sAKdw83o6BZi5j2CIeeqDan3GwrVizyh1aoGqe42hciXtLeLMqb/M9hxJIK/ydA64Ouly0qcTzLHOogJTGv0pj7F2NyYPobw71XIzErHoJQ/7HT38V5XlGhu13H3gEaWAZEUYa2UdoW9l6yh0IEIGQwCzzmPILvFfPP75f9+wrkI7wMHQw/cIjzNRzNGm/UPZtwaz6pAEhlhyNOUaYGy7i/n5OE5oB2lAEVZtKVB15QmyGEdYdjVUQa2GIFQdYfONtLCU9ht2MSjXzO/4DZfovgzJGaioAAAAASUVORK5CYII=)
      0 0 no-repeat;
    background-size: 36px 50px;
  }
}

.wrapperManger {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.blockTitle2 {
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 0 26px 0 22px;
  font-size: 20px;
  font-weight: 600;
  color: #3ff;
  line-height: 64px;
}

.wrapper {
  display: flex;
  @include mobile {
    display: block;
  }
}

.leftBlock {
  flex: 0 1 28%;
}

.centerBlock {
  flex: 0 1 44%;
  //background-color: #ffff00;
  @include mobile {
    margin-top: 20px;
  }
}

.rightBlock {
  flex: 0 1 20%;
  //background-color: #00ff00;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-top: 20px;
  margin-bottom: 15px;
  p:nth-of-type(2) {
    color: #ffffff;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.bottom2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  div {
    width: 33%;
    text-align: center;
    .one {
      color: #fff;
    }
    .studentCount {
      font-size: 25px;
      font-weight: bold;
      color: #dab606;
      .btn1 {
        font-size: 10px;
      }
    }
    .teacherCount {
      font-size: 25px;
      font-weight: bold;
      color: #488bf0;
      .btn2 {
        font-size: 10px;
      }
    }
    .price {
      font-size: 25px;
      font-weight: bold;
      color: #ee167b;
      .btn3 {
        font-size: 10px;
      }
    }
  }
}

.left {
  &::-webkit-scrollbar {
    width: 8px;
  }

  // 滚动条滑槽样式
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }
}

.data {
  display: flex;
  flex-wrap: wrap;

  .middle {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;

    p {
      font-size: 10px;
      cursor: pointer;
      margin-bottom: 4px;
    }
    p:nth-of-type(1) {
      width: 8px;
      border-radius: 50%;
      height: 8px;
      margin-left: 10px;
      margin-right: 2px;
    }
  }
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  width: 710px;
  height: 510px;
  // overflow-y: scroll;
  overflow-x: hidden;
  @include mobile {
    width: 100%;
    overflow-y: none;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  // 滚动条滑槽样式
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
  }
}

.buttons {
  margin-right: 40px;
}

.btn,
.btn:active,
.btn:focus {
  width: 120px;
  height: 40px;
  background: #052067;
  border: 2px #3a77a6 solid;
  color: #a3cade;
  font-size: 16px;
  outline: 0;
  transform: skewX(327deg);
  cursor: pointer;
  margin-left: 10px;

  span {
    transform: skewX(33deg);
    display: block;
  }
}

.btnActive {
  background: #0443bb !important;
  border-color: #20a8d5;
}

.firstWall {
  .title {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px 10px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    color: #3ff;
    line-height: 30px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  // 滚动条滑槽样式
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  .middleBlocks {
    display: flex;
    justify-content: space-around;
    @include mobile {
      display: block;
    }

    .item {
      position: relative;
      padding-bottom: 15px;
      flex: 0 1 24%;
      background-color: rgba(85, 85, 242, 0.2);
      border-radius: 10px;
      @include mobile {
        margin: 20px 0;
      }

      .heading {
        padding: 16px 0 0 20px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: #d5eaff;
        line-height: 20px;
      }

      .value {
        font-size: 32px;
        color: #24ffff;
        line-height: 38px;
        padding: 20px 0 0 20px;

        span {
          font-size: 12px;
          font-weight: 400;
          color: rgba(66, 183, 255, 0.8);
          line-height: 20px;
        }
      }
    }
  }

  .tl {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 9px;
    height: 9px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAq0lEQVQoU2NkwAH4Cv8LMTL9NGb681+cEV0Nb/4PdWamfzkMDIzajAyMZ/7/Z3iGooiv8GcE8///Cf+Y/s3+yMe5kaGB8Q/IELgisAKG/0G/WP7kfe3meYFsA1gRyAoWRoaJv1j/JKArgJskUPht8n9GhgMf+7jWYvMHI8gXTAw/1nzg53CDuQFdISN/8Q9Xpn8Mru/7OcpwBQejYP73mP+MjCIfJnBMwKUIANFdOsX6Hp/zAAAAAElFTkSuQmCC);
  }

  .tr {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 9px;
    height: 9px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAArElEQVQoU2MUzP8e84+F8eX/f+xnP/UzvmPAAhgFCn4UMDIySP1n+G/CwPD/6t9/TFM+T+S4iayWEc5p+M/C/+m7P9M/ptS/jIwLPvWzr4DJIRRBRbhLv0iw/WGZ9JeBcR1MIYYikFru0v8SbL9/LvjznyEfZDVWRSCF/EXfghn/Mzh86OfKxamIoeE/i8DHH7v+MXCE4FbEwMAgWPij6x8Tw268isDB8///GwCTVkAsu8o7zwAAAABJRU5ErkJggg==);
  }

  .bl {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 9px;
    height: 9px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAqUlEQVQoU2MUzP8e85+RUeTDBI4JDDgAI3/xD1emfwyu7/s5ynAq4iv8L8TE8GPNB34ON4YGxj/YFDKCBAUKv03+z8hw4GMf11qcinjzf6izMDJM/MX6J+FrN88LdIVgk0CAr/BnBDPD/6BfLH/y0BXCFcEV/v+f8I/p3+yPfJwbYW5EUQRSCLKamelfDgMDozYjA+OZ//8ZnmEoQlj/X4iR6acx05//4gB5+TrFu7kWzQAAAABJRU5ErkJggg==);
  }

  .br {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 9px;
    height: 9px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAApklEQVQoU2NkwAMECn4UMP7//4YRnyLBwh9d/5gYduNW1PCfReDjj13/GDhCcCriL/oWzPifweFDP1cuVkXcpf8l2H7/XPDnP0P+54kcNzEUcZd+kWD7wzLpLwPjuk/97CtAbkYoavjPwv/puz/TP6bUv4yMC2AKwIrA3mRkkPrP8N+EgeH/1b//mKaArED2NaNg/veYfyyML///Yz/7qZ/xHbYgAQDjXkAsUCel0gAAAABJRU5ErkJggg==);
  }
}

//------------------院校运行情况start
.blockTitle {
  height: 64px;
  padding: 0 26px 0 22px;
  font-size: 20px;
  font-weight: 600;
  color: #3ff;
  line-height: 64px;
}

.space {
  height: 20px;
}

.centerTitle {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 0%);
  @include mobile {
    position: unset;
    left: 0;
    top: 0;
    transform: translate(0%, 0%);
  }
}

.centerTitleCenter {
  margin: 0 auto;
  position: relative;
  height: 50px;
  display: inline-block;
}

.l {
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAEp0lEQVR4Xu3dW2/cRBgG4NfNkkNRD6RpC4SeCylkvTmsSg9py/aASiK1BNGNCtxzxwU3WXu3SP4N/AwESPwRrnuPhJCoEEKIPXpeNGYXGprZOGvvrj2elVaRHEdyHn3zeebzzNhC91P2OPm0hUfCR653zPwErOcRCi6v+AILBuY/gR1AxQqPNIAPDZACSB62Hd4TxOsG6R+BHRHUBToviBsGSAFU/JwvteZQNslaASQPF1ze9AXOmSjapYkFQNt8w7dwxwApgDyPB75toExiKutILyTpHkhhm1d9C28ZIIXAUo3znQ7uGiCFQPkbTjz9EY8FMZFlJGUTC5J1lXd9H/MGSCFgu7wkBN41QAqBtW0e+t3CRwaoj4DtclMIHM4qUt8cJFEWHV4G8bYBUggUKzzdAEoGSCFQ8jj9ax1bBsjkoV0F9sxB8q/yFV4ncDGLURQKaNXhxSZx3QApBK54PPxnHZsGqI9Avsot+pjOGlKoJhb0h6q8DR+nDJBCIF9lgT6WDZBCIKtl2NBNrOjxYKOORyaCTKLeIRA6goIO4zbfp4XXshRF+wKyHRYFsWiAVIn6Cc/5bdw0QOrSR+Zmf+yriZG0bAefETiQlSjaF5BEKbh86AscNUCqHrXDEonTBkghsORwpUPYBkgVQU94gW2sGSDVnczjXKOODQOkEHjH46RVx2MD1G9MVuEWkY3i2b5v80HxrML7AE5mIYoGArJdXhMCbxog1a3+Ky52WigaINWtvsZT7OC2AVIIZOkx0EA5KEuD1oGAsjRoHRjIrvCWAM7qnocGBlqscAmA/Gr9GRhopcYzrQ7e01pnt+VQYf/hZY9H23U8DHt+Ws8bOIKC9Rx1fKp7+XVgIBkRtssNITCX1ugIc92RgLKwCDgSUBZmnkUCsh2+IogHYUI1redEApKJ+rsGPtF5RVAkoCBRV7gugONpjZC9rjsykO5LFSID6b5UITKQ7ksVIgPpXvqIC0jbXWNiAdJ5ZB8LkM55KBYgnfNQbEC6TouJDWjF5fGWwPpePdO0/T42oOBRUA1l3VYExQYUTGpwuQaBC2mLkn7XGyuQjsOOeIHk9l6z2NKp/BErULdOrdXUmPiBNKsyxg4UdBodfuATJ3RI1kMBWnZ4tk3cMkAKgW6t+mNBzKQdaSgRFDSzKvO+j1UD1C+K6ngggCNpRhpaBEmUfJUn6UNOGU7tZ6hAAVLKN0YZOtD615z66WdspnVXz6EDBb1rh6ndgnkkQMFIP6V7oY0MSNaL8jWU0rZBysiAZBSVPOae1XGfwLG03NZGCiRRrn3JmT+msAHi5TQgjRxIosgJoKKJe77AwaQjjQVIosjdZJpN3KHAbJKRxgb0b05q4EaSl5mPFUgiybtbwcVqUjdNGTtQr3nJziSBy0nrcScGqNvkpp+1scpOcja1TBRQL5oKHk+giatJ2CMkkUASSlYlf2jiUlsgP84l6IkF6kWThPq+gzPoYGEcDwISD/R8H0lOXLcEFmjhvMBoXhSXKqAelhzT/fYXXsUE5uWrvkgcGlZnM5VA/8dY/4JTv8zgmJ/DbIAlx3nya2HSAnLCQo7ixVeFhUH9G3UMqxCVXeJ2AAAAAElFTkSuQmCC)
    0 0 no-repeat;
  background-size: 36px 50px;
  @include mobile {
    background: none;
  }
}

.m {
  display: block;
  line-height: 50px;
  font-size: 20px;
  margin: 0 36px 0 36px;
  background: rgba(44, 119, 255, 0.37);
  color: #3ff;
  font-size: 20px;
  font-weight: 600;
  @include mobile {
    background: none;
    margin: 0 36px 0 20px;
  }
}

.r {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 50px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABkCAYAAAAlg3YKAAAE30lEQVR4Xu3dzVMcRRgG8OfpHsJuqvRiebCseLA86cnyL7GsePTqzZvldQ9hZxat8uDNuxUBiVJRYxJNMB9FAlk+ZocPoWIiBgmpAAYhIUFmrK2SCmymoWd3A+zMy3GnZ4v+1dvdb/d0z/KtT6IPIH87BDRxz/f4U/VDCtCz0ZED+sslzgpQTMuhwmqliG9JRgIUA6Q1hv0ig61L0sRqkPQmev3PuCZA8QPTwniJZ7dfkgjaptEWYmD0U84IUFznDIRhHt0TBT4RoDggYjbw2F97SZrY/yJHHPw60sE/BCg+94nCdnTVNi/Jg55iPTN6yTC/LZIcoDxW4njcyC99EIAc0Fcu8YEAGeZegctTpgWNzEeQVvjNd3ldgAwCdHAx6OCfAmQQeO1VdJ35iI8FKEZAAQ8qJfaZcDKfB+kI034nrwmQQUC34bJ/grcEyARUszgmedCOdQysjXvs3S16Mt0HaYVbvsvLAmTKf9owFJzgpACZgDTOBkUuCJBBIPc6TpY/5IYAxSWICisVl9/thZPZTloBtyslXhIgU/5T8/RUEsUagZzG+XKR8xJBdc7gd+STWdv+oohHFY89NtGTyU6axN3A4zkBMnXQeyyx1t6WuTVppTBYcTklEWTKoBOMYJnsg3J5fFMu8KFEUIyAJjZ8jydtcTIXQQQWgxJ/ECCDQJvCzVGXVwXIvAY0GhTpC5ApgohLox5vC5BBQBGnKx6XBcgg8OY7+KrnfW4KUIxA9YjBbttcTGiZmWoo4q+Kx5+TRE+28iBictzjkAAZBByFa2MupwXInANZPQfL7HLHi0/QM/A5H0kExY1gwHpQYndSnMx00lpjzi/yFwEyTTE0/NEiRwXIILD9kG5SpEwkilEepyYKXE2Kk4k+iMTjwGNXPTjZAIowH3TyvAAZBLRG4Bc5LEAmoAgX/E7eESCDQJTH13EnCW3BUj2KUWEpcPm9LUZcuVQDoc4lju1QqQZqJEHcQko10Mt5dPcXuC5NLEbA5qiTDVxqI4gOJoIO3rBB2K1MaoGO5nFuqMC7AhQ7NmPjeA5dhQJDAYrvf6w3iu8FmMompogrFY+/71V5m+upA6JCdOwVdO92ktkGJs15kPFFJUlgUgtkc1A3CVSqmlh1F/17OfQ2Y/RKZQS1NfD0whRVqYmgaufc3o7eJFt8bZpaaoCSHJKzgUldE8vl8WO5wPtJKm9TNhURpBRmKi4HbCqctEzLA1Fj/dg/6DvzhfkVN0lRUrWi2MxpRerWpKkxHxTrfyhoE1kt28QUsXk0h9PXC1yxqWi9ZVoWqBkL8jZoLQl0ROHGiMsJmwo2WqblgBwHU2MdHGy04rb3txSQJu6Mubi49UJ+20o2Uq6VgBZyy7hQ/nLvN7Y0AlJ7b2sAKdw83o6BZi5j2CIeeqDan3GwrVizyh1aoGqe42hciXtLeLMqb/M9hxJIK/ydA64Ouly0qcTzLHOogJTGv0pj7F2NyYPobw71XIzErHoJQ/7HT38V5XlGhu13H3gEaWAZEUYa2UdoW9l6yh0IEIGQwCzzmPILvFfPP75f9+wrkI7wMHQw/cIjzNRzNGm/UPZtwaz6pAEhlhyNOUaYGy7i/n5OE5oB2lAEVZtKVB15QmyGEdYdjVUQa2GIFQdYfONtLCU9ht2MSjXzO/4DZfovgzJGaioAAAAASUVORK5CYII=)
    0 0 no-repeat;
  background-size: 36px 50px;
  @include mobile {
    background: none;
  }
}

.centerContent {
  padding: 30px 20px 30px 20px;
  display: flex;
  justify-content: space-between;
  @include mobile {
    flex-wrap: wrap;
    padding: 10px;
  }
}

.centerSubContent {
  width: 30%;
  padding: 0 0 25px;
  background: rgba(85, 85, 242, 0.2);
  border-radius: 8px;
  @include mobile {
    width: 100%;
    margin: 10px;
  }

  .cSubTitle {
    color: #d5eaff;
    font-size: 16px;
    font-weight: 500;
    padding: 14px 0 0 20px;
    line-height: 22px;
  }

  .cSubResult {
    font-size: 36px;
    font-weight: 600;
    color: #24ffff;
    line-height: 38px;
    padding: 20px 0 0 20px;
  }

  .unit {
    font-size: 12px;
    font-weight: 400;
    color: #42b7ff;
    line-height: 20px;
    font-style: normal;
  }
}

.tabTitle {
  position: relative;
  z-index: 2;

  .tabUl {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 0;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    display: block;
    width: 100%;
    height: 54px;
    line-height: 54px;
    background: url(Wall2/components/TabHeader/img/bg.png) center 0 no-repeat;
    background-size: contain;
  }

  .tabLi {
    width: 50%;
    height: 54px;
    float: left;
    text-align: center;
    color: #278bc1;
    font-size: 20px;
    line-height: 54px;
    cursor: pointer;
    position: relative;
  }

  .spanL {
    position: absolute;
    right: 27px;
  }

  .spanR {
    width: 60%;
    height: 54px;
    float: left;
    text-align: center;
    color: #278bc1;
    font-size: 20px;
    line-height: 54px;
    cursor: pointer;
    position: absolute;
    left: 17px;
  }

  .tab2 {
    background: url(Wall2/components/TabHeader/img/bg2.png) center 0 no-repeat;
    background-size: contain;
  }

  .active {
    color: #3ff;
    font-weight: 600;
  }
}

//------------------院校运行情况end
